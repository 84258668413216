<template>
    <navbar :navLight="'nav-light'" />
    <!-- Start Hero -->
    <section
        class="relative table w-full py-36 lg:py-44 bg-[url('/src/assets/images/course/cta.jpg')] bg-no-repeat bg-center bg-cover">
        <div class="absolute inset-0 bg-black opacity-75"></div>
        <div class="container relative">
            <div class="grid grid-cols-1 pb-8 text-center mt-10">
                <h3 class="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">Courses</h3>
                <h5 class="text-white/50 text-lg font-medium">Please see our latest educational courses</h5>
            </div><!--end grid-->
        </div><!--end container-->
    </section><!--end section-->
    <div class="relative">
        <div
            class="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
            <svg class="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!-- End Hero -->

    <section class="relative md:py-24 py-16 overflow-hidden">
        <div class="container relative">
            <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
              
                <!-- Start Course -->
                <div v-for="item in datas" :key="item.id" @click="viewBlogDetail.id"
                    class="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                    <div class="relative overflow-hidden">
                        <img :src="item.image" class="group-hover:scale-110 duration-500 ease-in-out" alt="">
                        <div
                            class="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                        </div>

                        <div
                            class="absolute start-0 bottom-0 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                            
                        </div>
                    </div>

                    <div class="content p-6 relative">
                        <router-link :to="{ name: 'course-detail', params: { id: item.id } }" class="font-medium block text-indigo-600">{{ item.course }}</router-link>
                        <router-link :to="{ name: 'course-detail', params: { id: item.id } }"
                            class="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2">{{ item.title }}</router-link>
                        <p class="text-slate-400 mt-3 mb-4">{{ item.desc }}</p>

                       

                        <div
                            class="absolute -top-7 end-6 z-1 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                           
                        </div>
                    </div>
                </div>
                <!-- End Course -->
            </div>
          
        </div>

      
    </section>
    <footers/>
     <switcher />
</template>

<script>
import navbar from '@/components/navbar/navbar.vue';
import footers from '@/components/footer/footer.vue';
import switcher from '@/components/switcher.vue';

export default {
    data() {
        return {
            datas: [
            {
                id: 1,
                image: require('/src/assets/images/course/c1.jpg'),
                image2: require('/src/assets/images/client/01.jpg'),
                name: 'Calvin Carlo',
                position: 'Professor',
                course: 'DevOps Training',
                title: 'Fundamentals and Practices of DevOps',
                desc: 'Gain proficiency in setting up and managing DevOps processes.',
                lectures: '25 Lectures',
                time: '1h 30m',
                view: '3012',
                price: '$11'
            },
            {
                id: 2,
                image: require('/src/assets/images/course/c2.jpg'),
                image2: require('/src/assets/images/client/02.jpg'),
                name: 'Christa Smith',
                position: 'Professor',
                course: 'Agile Training',
                title: 'Agile Principles and Scrum/Kanban Practices',
                desc: 'Improve your project processes by applying Agile methodologies.',
                lectures: '25 Lectures',
                time: '1h 30m',
                view: '3012',
                price: '$11'
            },
            {
                id: 3,
                image: require('/src/assets/images/course/c3.jpg'),
                image2: require('/src/assets/images/client/03.jpg'),
                name: 'Jani Jangad',
                position: 'Professor',
                course: 'SDLC Training',
                title: 'Software Development Life Cycle (SDLC)',
                desc: 'Learn to manage SDLC phases and develop high-quality software.',
                lectures: '25 Lectures',
                time: '1h 30m',
                view: '3012',
                price: '$11'
            },
            {
                id: 4,
                image: require('/src/assets/images/course/c4.jpg'),
                image2: require('/src/assets/images/client/04.jpg'),
                name: 'John Cartwright',
                position: 'Professor',
                course: 'Git Training',
                title: 'Version Control Management with Git',
                desc: 'Learn Git commands and best practices to manage your code.',
                lectures: '25 Lectures',
                time: '1h 30m',
                view: '3012',
                price: '$11'
            },
            {
                id: 5,
                image: require('/src/assets/images/course/c5.jpg'),
                image2: require('/src/assets/images/client/05.jpg'),
                name: 'Sally Short',
                position: 'Professor',
                course: 'Docker Training',
                title: 'Container Management with Docker',
                desc: 'Gain proficiency in creating, managing, and scaling Docker containers.',
                lectures: '25 Lectures',
                time: '1h 30m',
                view: '3012',
                price: '$11'
            },
            {
                id: 6,
                image: require('/src/assets/images/course/c6.jpg'),
                image2: require('/src/assets/images/client/06.jpg'),
                name: 'William Benson',
                position: 'Professor',
                course: 'CI/CD Training',
                title: 'Automation of CI/CD Processes',
                desc: 'Master the setup and management of CI/CD processes.',
                lectures: '25 Lectures',
                time: '1h 30m',
                view: '3012',
                price: '$11'
            },
            {
                id: 7,
                image: require('/src/assets/images/course/c7.jpg'),
                image2: require('/src/assets/images/client/01.jpg'),
                name: 'William Benson',
                position: 'Professor',
                course: 'GitOps Training',
                title: 'Infrastructure Management with GitOps',
                desc: 'Learn to manage infrastructure through Git and improve workflows.',
                lectures: '25 Lectures',
                time: '1h 30m',
                view: '3012',
                price: '$11'
            },
            {
                id: 8,
                image: require('/src/assets/images/course/c8.jpg'),
                image2: require('/src/assets/images/client/02.jpg'),
                name: 'William Benson',
                position: 'Professor',
                course: 'DevSecOps Training',
                title: 'Security Automation with DevSecOps',
                desc: 'Develop secure software by integrating security into DevOps processes.',
                lectures: '25 Lectures',
                time: '1h 30m',
                view: '3012',
                price: '$11'
            },
            {
                id: 9,
                image: require('/src/assets/images/course/c9.jpg'),
                image2: require('/src/assets/images/client/03.jpg'),
                name: 'William Benson',
                position: 'Professor',
                course: 'Azure Cloud Training',
                title: 'Application Development with Azure Cloud',
                desc: 'Develop and manage cloud-based applications on Azure.',
                lectures: '25 Lectures',
                time: '1h 30m',
                view: '3012',
                price: '$11'
            }
        ]
        }
    },
    components: {
        navbar,        
        switcher,
        footers
    },
    methods: {
        viewBlogDetail(id) {
            this.$router.push({ name: 'course-detail', params: { id } });
        }
    }
}
</script>

<style lang="scss" scoped></style>
