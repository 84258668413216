<template>
    <!-- Start Section-->

    <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
        <div v-for="item in datas" :key="item" class="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
            <div class="relative overflow-hidden text-transparent -m-3">
                <i :data-feather="item.icon" class="size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"></i>
                <div class="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                    <i :class="item.icon2"></i>
                </div>
            </div>

            <div class="mt-6">
                <router-link :to="{ name: 'course-detail', params: { id: item.id } }" class="text-lg font-medium group-hover:text-white duration-500">
                    {{ item.title }}
                </router-link>
                <p class="text-slate-400 group-hover:text-white/50 duration-500 mt-3">{{ item.desc }}</p>
            </div>
        </div><!--end feature-->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                datas: [
                    {
                        id: 1,
                        image: require('/src/assets/images/course/c1.jpg'),
                        image2: require('/src/assets/images/client/01.jpg'),
                        name: 'Calvin Carlo',
                        position: 'Professor',
                        course: 'DevOps Training',
                        icon: 'hexagon',
              icon2: 'uil uil-chart-line',
                        title: 'Fundamentals and Practices of DevOps',
                        desc: 'Gain proficiency in setting up and managing DevOps processes.',
                        lectures: '25 Lectures',
                        time: '1h 30m',
                        view: '3012',
                        price: '$11'
                    },
                    {
                        id: 2,
                        image: require('/src/assets/images/course/c2.jpg'),
                        image2: require('/src/assets/images/client/02.jpg'),
                        name: 'Christa Smith',
                        position: 'Professor',
                        course: 'Agile Training',
                        icon: 'hexagon',
              icon2: 'uil uil-crosshairs',
                        title: 'Agile Principles and Scrum/Kanban Practices',
                        desc: 'Improve your project processes by applying Agile methodologies.',
                        lectures: '25 Lectures',
                        time: '1h 30m',
                        view: '3012',
                        price: '$11'
                    },
                    {
                        id: 3,
                        image: require('/src/assets/images/course/c3.jpg'),
                        image2: require('/src/assets/images/client/03.jpg'),
                        name: 'Jani Jangad',
                        position: 'Professor',
                        icon: 'hexagon',
                        icon2: 'uil uil-airplay',
                        course: 'SDLC Training',
                        title: 'Software Development Life Cycle (SDLC)',
                        desc: 'Learn to manage SDLC phases and develop high-quality software.',
                        lectures: '25 Lectures',
                        time: '1h 30m',
                        view: '3012',
                        price: '$11'
                    },
                    {
                        id: 4,
                        image: require('/src/assets/images/course/c4.jpg'),
                        image2: require('/src/assets/images/client/04.jpg'),
                        name: 'John Cartwright',
                        position: 'Professor',
                        icon: 'hexagon',
              icon2: 'uil uil-rocket',
                        course: 'Git Training',
                        title: 'Version Control Management with Git',
                        desc: 'Learn Git commands and best practices to manage your code.',
                        lectures: '25 Lectures',
                        time: '1h 30m',
                        view: '3012',
                        price: '$11'
                    },
                    {
                        id: 5,
                        image: require('/src/assets/images/course/c5.jpg'),
                        image2: require('/src/assets/images/client/05.jpg'),
                        name: 'Sally Short',
                        position: 'Professor',
                        icon: 'hexagon',
              icon2: 'uil uil-gear',
                        course: 'Docker Training',
                        title: 'Container Management with Docker',
                        desc: 'Gain proficiency in creating, managing, and scaling Docker containers.',
                        lectures: '25 Lectures',
                        time: '1h 30m',
                        view: '3012',
                        price: '$11'
                    },
                    {
                        id: 6,
                        image: require('/src/assets/images/course/c6.jpg'),
                        image2: require('/src/assets/images/client/06.jpg'),
                        name: 'William Benson',
                        position: 'Professor',
                        course: 'CI/CD Training',
                        icon: 'hexagon',
              icon2: 'uil uil-chart-pie-alt',
                        title: 'Automation of CI/CD Processes',
                        desc: 'Master the setup and management of CI/CD processes.',
                        lectures: '25 Lectures',
                        time: '1h 30m',
                        view: '3012',
                        price: '$11'
                    },
                    {
                        id: 7,
                        image: require('/src/assets/images/course/c7.jpg'),
                        image2: require('/src/assets/images/client/01.jpg'),
                        name: 'William Benson',
                        position: 'Professor',
                        icon: 'hexagon',
              icon2: 'uil uil-building',
                        course: 'GitOps Training',
                        title: 'Infrastructure Management with GitOps',
                        desc: 'Learn to manage infrastructure through Git and improve workflows.',
                        lectures: '25 Lectures',
                        time: '1h 30m',
                        view: '3012',
                        price: '$11'
                    },
                    {
                        id: 8,
                        image: require('/src/assets/images/course/c8.jpg'),
                        image2: require('/src/assets/images/client/02.jpg'),
                        name: 'William Benson',
                        position: 'Professor',
                        icon: 'hexagon',
              icon2: 'uil uil-lightbulb-alt',
                        course: 'DevSecOps Training',
                        title: 'Security Automation with DevSecOps',
                        desc: 'Develop secure software by integrating security into DevOps processes.',
                        lectures: '25 Lectures',
                        time: '1h 30m',
                        view: '3012',
                        price: '$11'
                    }
                ]
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
