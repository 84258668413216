<template>
  <navbar />
  
  <!-- Start About Me -->
  <section class="relative table w-full py-36 pb-0 lg:py-44 lg:pb-0 bg-indigo-600">
    <div class="container relative">
      <div class="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
        <div class="lg:col-span-7">
          <div class="md:me-6 md:mb-20">
            <h5 class="text-lg text-white/60">About Me</h5>
            <h4 class="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-white">
              DevOps Engineer with Extensive Experience in Cloud and Security Solutions
            </h4>
            <p class="text-white/60 text-lg max-w-xl">
              I am a seasoned DevOps Engineer with a proven track record in optimizing complex infrastructures, driving digital transformation, and enhancing security across various platforms. With expertise in cloud computing (Azure, AWS), CI/CD pipelines, and container orchestration (Kubernetes, Docker), I have successfully led numerous projects that require a deep understanding of both development and operations.
            </p>
          
            <div class="mt-6">
       
            </div>
          </div>
        </div>
        <!--end col-->
        <div class="lg:col-span-5">
          <!-- Optional: Add a professional image or illustration related to DevOps here -->
        </div>
        <!--end col-->
      </div>
      <!--end grid-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->

  <div class="relative">
    <div class="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-gray-50 dark:text-slate-800">
      <svg
        class="w-full h-auto scale-[2.0] origin-top"
        viewBox="0 0 2880 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
  </div>
  <!-- End About Me -->
  
 

  <powerfull :powerfull="true" />

  <experience />

  <div style="height: 100px;"></div>
  
  <categories />

  <div style="height: 100px;"></div>

  <Footer/>
  <cookies />
  <switcher />
</template>

<script>
import navbar from '@/components/navbar/navbar.vue';
import powerfull from "@/components/powerful-digitalization.vue";
import categories from '@/components/podcart/podcart-categories.vue';
import Footer from "@/components/footer/footer.vue";
import cookies from "@/components/switcher.vue";
import switcher from "@/components/switcher.vue";
import experience from '@/components/personal/work-experience.vue';

export default {
  components: {
    navbar,
    powerfull,
    Footer,
    categories,
    cookies,
    switcher,
    experience,
  },
  data() {
    return {
      datas: [
                {
                    image: require('../../assets/images/client/circle-logo.png'),
                    name: 'CircleCI',
                    country: 'U.S.A.'
                },
                {
                    image: require('../../assets/images/client/android.png'),
                    name: 'Android',
                    country: 'U.S.A.'
                },
                {
                    image: require('../../assets/images/client/facebook-logo-2019.png'),
                    name: 'Facebook',
                    country: 'U.S.A.'
                },
                {
                    image: require('../../assets/images/client/google-logo.png'),
                    name: 'Google',
                    country: 'U.S.A.'
                },
                {
                    image: require('../../assets/images/client/lenovo-logo.png'),
                    name: 'Lenovo',
                    country: 'U.S.A.'
                },
                {
                    image: require('../../assets/images/client/linkedin.png'),
                    name: 'Linkedin',
                    country: 'U.S.A.'
                },
                {
                    image: require('../../assets/images/client/shree-logo.png'),
                    name: 'Shreethemes',
                    country: 'U.S.A.'
                },
                {
                    image: require('../../assets/images/client/skype.png'),
                    name: 'Skype',
                    country: 'U.S.A.'
                },
                {
                    image: require('../../assets/images/client/snapchat.png'),
                    name: 'Snapchat',
                    country: 'U.S.A.'
                },
                {
                    image: require('../../assets/images/client/spotify.png'),
                    name: 'Spotify',
                    country: 'U.S.A.'
                },
                {
                    image: require('../../assets/images/client/telegram.png'),
                    name: 'Telegram',
                    country: 'U.S.A.'
                },
                {
                    image: require('../../assets/images/client/whatsapp.png'),
                    name: 'Whatsapp',
                    country: 'U.S.A.'
                },
                
            ],
      selectedFile: null,
      pages: [],
      loading: false,
      popupImage: null,
      pageIdCounter: 0, // Statik sayfa kimliği için sayaç
    };
  },
  methods: {
    handleClickOutside(event) {
      const element = this.$refs.yourElement;
      if (!element || !element.contains) {
        return;
      }
      if (!element.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    }
  },
};
</script>

<style>
.thumbnails-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.thumbnail {
  margin: 10px;
  position: relative;
  text-align: center;
  cursor: pointer;
}

.thumbnail img {
  max-width: 150px;
  height: auto;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  transition: transform 0.2s ease;
}

.thumbnail:hover img {
  transform: scale(1.1);
}

.delete-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
}

.popup-content img {
  max-width: 100%;
  height: auto;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
}

.draggable-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
