<template>
   <!-- Start -->
   <section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
            <div class="container relative">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">Experience</h6>
                    <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Work Experience</h3>

                    <p class="text-slate-400 max-w-xl mx-auto">I am a seasoned DevOps Engineer with comprehensive experience in transforming and optimizing development operations across various industries. My expertise includes on-premise and cloud-based systems, Agile methodologies, and cutting-edge DevOps tools.</p>
                </div><!--end grid-->

                <div class="grid grid-cols-1 mt-8">
                    <div class="relative after:content-[''] after:absolute after:top-0 md:after:end-0 md:after:start-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">
                        <!--Start content-->
                        <div class="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div class="grid md:grid-cols-2">
                                <div class="md:text-end md:me-8 relative">
                                    <img src="../../assets/images/client/arcelik.png" class="rounded-full size-9 md:me-auto" alt="">
                                    <h5 class="my-2 font-semibold text-lg">Arçelik</h5>
                                    <h6 class="text-slate text-sm mb-0">Apr 2024 - Present</h6>
                                </div>

                                <div class="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                    <h5 class="title mb-1 font-semibold">DevOps Engineer</h5>
                                    <p class="mt-3 mb-0 text-slate-400">Led the management of Kubernetes configurations and Azure DevOps pipelines in an Agile environment. Integrated SonarCloud to enforce clean code, and proactively resolved system errors. Ensured seamless coordination with diverse software teams to address immediate DevOps needs.</p>
                                </div>
                            </div>
                        </div>
                        <!--End content-->

                        <!--Start content-->
                        <div class="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div class="grid md:grid-cols-2">
                                <div class="text-start ms-8 relative md:order-2">
                                    <img src="../../assets/images/client/vakifbank.png" class="rounded-full size-9 md:me-auto" alt="">
                                    <h5 class="my-2 font-semibold text-lg">Vakıfbank</h5>
                                    <h6 class="text-slate text-sm mb-0">Apr 2023 - Apr 2024</h6>
                                </div>

                                <div class="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                    <h5 class="title mb-1 font-semibold">DevOps Engineer</h5>
                                    <p class="mt-3 mb-0 text-slate-400">Managed critical DevOps operations for platforms like Vakıfpays and E-Invoice. Utilized tools like Kubernetes, Docker, and Azure DevOps to improve system efficiency. Led migrations, troubleshooting, and documentation for system-wide improvements, while contributing to internal R&D projects.</p>
                                </div>
                            </div>
                        </div>
                        <!--End content-->

                        <!--Start content-->
                        <div class="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div class="grid md:grid-cols-2">
                                <div class="md:text-end md:me-8 relative">
                                    <img src="../../assets/images/client/testinium.png" class="rounded-full size-9 md:me-auto" alt="">
                                    <h5 class="my-2 font-semibold text-lg">Testinium</h5>
                                    <h6 class="text-slate text-sm mb-0">Mar 2022 - Apr 2023</h6>
                                </div>

                                <div class="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                    <h5 class="title mb-1 font-semibold">DevOps Engineer</h5>
                                    <p class="mt-3 mb-0 text-slate-400">Facilitated DevOps transformations for clients such as Kariyer.net and ING Bank. Implemented Kubernetes, Docker, and AWS integrations for cloud products. Designed an agile Git Flow structure and supported the deployment of 30+ customer projects using Azure and SonarQube.</p>
                                </div>
                            </div>
                        </div>
                        <!--End content-->

                        <!--Start content-->
                        <div class="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div class="grid md:grid-cols-2">
                                <div class="text-start ms-8 relative md:order-2">
                                    <img src="../../assets/images/client/hmb.png" class="rounded-full size-9 md:me-auto" alt="">
                                    <h5 class="my-2 font-semibold text-lg">Ministry of Treasury and Finance</h5>
                                    <h6 class="text-slate text-sm mb-0">Jan 2021 - Mar 2022</h6>
                                </div>

                                <div class="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                    <h5 class="title mb-1 font-semibold">DevOps Engineer</h5>
                                    <p class="mt-3 mb-0 text-slate-400">Developed GitLab CI/CD pipelines and Dockerized key projects. Migrated legacy SVN and TFS projects to Git and provided extensive training on Agile processes for 100+ IT personnel. Spearheaded digital transformation for critical government departments using Kubernetes and Jenkins.</p>
                                </div>
                            </div>
                        </div>
                        <!--End content-->

                        <!--Start content-->
                        <div class="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div class="grid md:grid-cols-2">
                                <div class="md:text-end md:me-8 relative">
                                    <img src="../../assets/images/client/google-logo.png" class="rounded-full size-9 md:me-auto" alt="">
                                    <h5 class="my-2 font-semibold text-lg">SAFRANTEK</h5>
                                    <h6 class="text-slate text-sm mb-0">Jun 2018 - Dec 2020</h6>
                                </div>

                                <div class="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                    <h5 class="title mb-1 font-semibold">Azure Cloud & DevOps Engineer</h5>
                                    <p class="mt-3 mb-0 text-slate-400">Oversaw cloud and DevOps operations for B2B and B2C platforms of ING Group's Turkish subsidiary. Managed Azure infrastructure, security analysis, and system architecture, ensuring compliance with Agile Scrum methodologies.</p>
                                </div>
                            </div>
                        </div>
                        <!--End content-->

                     

                        <!--Start content-->
                        <div class="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div class="grid md:grid-cols-2">
                                <div class="text-start ms-8 relative md:order-2">
                                    <img src="../../assets/images/client/google-logo.png" class="rounded-full size-9 md:me-auto" alt="">
                                    <h5 class="my-2 font-semibold text-lg">Agile Response Technologies</h5>
                                    <h6 class="text-slate text-sm mb-0">Jan 2018 - Jun 2018</h6>
                                </div>

                                <div class="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                    <h5 class="title mb-1 font-semibold">Engineering Intern</h5>
                                    <p class="mt-3 mb-0 text-slate-400">Developed and analyzed C#-based technologies and performed file system analysis for enhanced system capabilities.</p>
                                </div>
                            </div>
                        </div>
                        <!--End content-->

                        <!--Start content-->
                        <div class="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div class="grid md:grid-cols-2">
                                <div class="md:text-end md:me-8 relative">
                                    <img src="../../assets/images/client/google-logo.png" class="rounded-full size-9 md:me-auto" alt="">
                                    <h5 class="my-2 font-semibold text-lg">Center for Cyber and Information Security (CCIS)</h5>
                                    <h6 class="text-slate text-sm mb-0">Jun 2017 - Sep 2017</h6>
                                </div>

                                <div class="ltr:float-left rtl:float-right text-start ms-8 mt-6 md:mt-0">
                                    <h5 class="title mb-1 font-semibold">Engineering Intern</h5>
                                    <p class="mt-3 mb-0 text-slate-400">Focused on developing behavioral biometric techniques and applying data mining techniques for advanced cybersecurity systems.</p>
                                </div>
                            </div>
                        </div>
                        <!--End content-->

                        <!--Start content-->
                        <div class="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-indigo-600 md:after:mx-auto md:after:end-0 md:after:start-0 after:-start-9 before:content-[''] before:absolute md:before:mx-auto md:before:end-0 md:before:start-0 before:-start-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div class="grid md:grid-cols-2">
                                <div class="text-start ms-8 relative md:order-2">
                                    <img src="../../assets/images/client/google-logo.png" class="rounded-full size-9 md:me-auto" alt="">
                                    <h5 class="my-2 font-semibold text-lg">RDC Partner</h5>
                                    <h6 class="text-slate text-sm mb-0">Jun 2016 - Sep 2016</h6>
                                </div>

                                <div class="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                    <h5 class="title mb-1 font-semibold">Engineering Intern</h5>
                                    <p class="mt-3 mb-0 text-slate-400">Developed web services, prepared ORM-compatible database systems, and adapted Linq queries for full system integration.</p>
                                </div>
                            </div>
                        </div>
                        <!--End content-->
                        
                    </div>
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->
</template>

<script>
    export default {
        data() {
            return {
                datas: 'The above experiences have honed my ability to deliver high-quality, efficient, and secure DevOps solutions tailored to meet the needs of various industries.'
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>