<template>
    <navbar :navLight="'nav-light'" />
    <!-- Start Hero -->
    <section
        class="relative table w-full py-36 lg:py-44 bg-[url('/src/assets/images/services.jpg')] bg-no-repeat bg-center bg-cover">
        <div class="absolute inset-0 bg-black opacity-75"></div>
        <div class="container relative">
            <div class="grid grid-cols-1 pb-8 text-center mt-10">
                <h3 class="mt-2 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">Consultancy</h3>
            </div><!--end grid-->
        </div><!--end container-->
    </section><!--end section-->

    <div style="height: 150px;"></div>

    <div class="container relative">
        <div class="grid md:grid-cols-2 grid-cols-1 pb-8 items-center">
            <div>
                <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">Consultancy</h6>
                <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Your Partner in Digital Transformation.</h3>
            </div>
            <p class="text-slate-400 max-w-xl">I offer expert consulting services to help businesses streamline their operations, enhance security, and drive innovation. With tailored B2B/B2C solutions, I ensure that your systems are optimized and ready for the future. Let’s work together to turn challenges into opportunities and transform your digital landscape.</p>
        </div><!--end grid-->
        <whatwedo />
    </div>

    <div style="height: 150px;"></div>
    
    <Footer/>
    <switcher />
</template>

<script>
import navbar from '@/components/navbar/navbar.vue'
import whatwedo from '@/components/what-we-do/what-we-do-it-solution-detailed-consult.vue';
import Footer from '@/components/footer/footer.vue';
import switcher from '@/components/switcher.vue'

export default {
    components: {
        navbar,
        whatwedo,
        Footer,
        switcher
    }
}
</script>

<style lang="scss" scoped></style>