<template>
   

    
    <!-- Start Section-->
    <section class="relative md:py-24 py-16">
        <div class="container relative">
            <div class="grid grid-cols-1 items-center gap-[30px]">
              
            </div><!--grid-->

            <div class="grid mx-auto lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 mt-4 gap-[30px]">
                <div v-for="item in filteredData" :key="item.id" class="picture-item">
                    <div class="group relative block overflow-hidden rounded-md duration-500">
                        <router-link :to="{ name: 'project-detail', params: { id: item.id } }"><img :src="item.image" class="rounded-md" alt=""></router-link>
                        <div class="content pt-3">
                            <h5 class="mb-1"><router-link :to="{ name: 'project-detail', params: { id: item.id } }" class="hover:text-indigo-600 duration-500 font-semibold">{{item.title}}</router-link></h5>
                            <h6 class="text-slate-400">{{item.name}}</h6>
                            <p class="text-slate-400 mt-2">{{item.desc}}</p>
                        </div>
                    </div>
                </div>
            </div>

           
        </div><!--end container-->
       
    </section><!--end section-->
    <!-- End Section-->
   <!-- <Footer :email=true /> -->
</template>

<script>

import image1 from '/src/assets/images/portfolio/01.jpg';
import image2 from '/src/assets/images/portfolio/02.jpg';
import image3 from '/src/assets/images/portfolio/03.jpg';
import image4 from '/src/assets/images/portfolio/04.jpg';
// Other image imports...

export default {
    data() {
        return {
            selectedCategory: null,
            datas: [
                {
                    id: 1,
                    image: image1,
                    name: 'Ministry of Treasury and Finance',
                    title: 'DevOps Transformation Project',
                    category: 'development',
                    desc: 'We optimized the software development processes within the ministry using modern DevOps methodologies.'
                },
                {
                    id: 2,
                    image: image2,
                    name: 'Turkcell, Türk Telekom, Vodafone',
                    title: 'Joint Data Storage System Project',
                    category: 'development',
                    desc: 'We created a centralized data management platform to securely store the data of telecom operators.'
                },
                {
                    id: 3,
                    image: image3,
                    name: 'ING Group Turkey',
                    title: 'B2B/B2C Azure Cloud and DevOps Project',
                    category: 'development',
                    desc: 'We ensured scalability and security by migrating ING’s existing systems to the Azure Cloud environment.'
                },
                {
                    id: 4,
                    image: image4,
                    name: 'Bilyoner',
                    title: 'Amazon Cloud Transformation Project',
                    category: 'development',
                    desc: 'We ensured performance and reliability by migrating Bilyoner’s high-traffic platform to AWS cloud.'
                },
                {
                    id: 5,
                    image: image1,
                    name: 'Vakıfbank',
                    title: 'DevOps and ItOps Transformation Project',
                    category: 'development',
                    desc: 'We restructured Vakıfbank’s software development and operations processes.'
                },
                {
                    id: 6,
                    image: image2,
                    name: 'Paycore',
                    title: 'DevOps Transformation Project',
                    category: 'development',
                    desc: 'We optimized Paycore’s software development and deployment processes using DevOps methodologies.'
                },
                {
                    id: 7,
                    image: image3,
                    name: 'Multinet',
                    title: 'DevOps Transformation Project',
                    category: 'development',
                    desc: 'We increased operational efficiency by optimizing Multinet’s software development processes.'
                },
                {
                    id: 8,
                    image: image4,
                    name: 'Erdemir',
                    title: 'DevOps Transformation Project',
                    category: 'development',
                    desc: 'We restructured Erdemir’s IT processes, speeding up software development cycles.'
                },
                {
                    id: 9,
                    image: image1,
                    name: 'Testinium',
                    title: 'DevOps Transformation and Cloud Compliance Project',
                    category: 'development',
                    desc: 'We optimized Testinium’s software development processes using DevOps methodologies.'
                },
                {
                    id: 10,
                    image: image2,
                    name: 'Ofix',
                    title: 'DevOps and Azure Cloud Transformation Project',
                    category: 'development',
                    desc: 'We made Ofix’s IT infrastructure more flexible, secure, and scalable.'
                },
                {
                    id: 11,
                    image: image3,
                    name: 'Ofix',
                    title: 'KVKK Compliance Project',
                    category: 'development',
                    desc: 'We ensured full compliance with KVKK by analyzing Ofix’s data processing processes.'
                },
                {
                    id: 12,
                    image: image4,
                    name: 'Boğaziçi University',
                    title: 'TÜBİTAK Security Compliance Project',
                    category: 'development',
                    desc: 'We ensured that the university’s information systems meet TÜBİTAK security standards.'
                },
                {
                    id: 13,
                    image: image1,
                    name: 'Testinium',
                    title: 'BizDevOps Projects',
                    category: 'development',
                    desc: 'We ensured that Testinium’s business units work more harmoniously with software development teams.'
                },
                {
                    id: 14,
                    image: image2,
                    name: 'Felixo',
                    title: 'Azure Cloud Management and Cost Optimization Project',
                    category: 'development',
                    desc: 'We developed strategies to increase cost efficiency by analyzing Felixo’s cloud infrastructure.'
                },
            ],
            filteredData: this.datas,
        };
    },
    components: {
    },
    created() {
        this.matchCategory(null); // Show all projects initially
    },
    methods: {
        matchCategory(category) {
            this.selectedCategory = category;
            this.filteredData = this.selectedCategory
                ? this.datas.filter(item => item.category === this.selectedCategory)
                : this.datas;
        },
    },
};
</script>

<style lang="scss" scoped></style>
