<template>
    <div class="container relative">
        <div class="grid grid-cols-1 pb-8 text-center">
            <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Skills</h3>

            <p class="text-slate-400 max-w-xl mx-auto">My expertise spans across various DevOps tools, cloud platforms, cybersecurity, and software development processes. Below are some of the key skills I specialize in.</p>
        </div><!--end grid-->

        <div class="flex justify-center items-center text-center mt-8">
            <ul class="list-none space-x-3 space-y-4">
                <li v-for="item in categories" :key="item" class="inline-block">
                    <a href=""
                        class="group flex items-center bg-white dark:bg-slate-900 hover:text-indigo-600 shadow hover:shadow-md dark:shadow-gray-800 hover:dark:shadow-gray-800 border-4 border-double border-gray-100 hover:border-indigo-600/30 dark:border-gray-800 hover:dark:border-indigo-600/50 py-1.5 px-4 rounded-full align-middle duration-500">
                        <i :class="item.icon" class="me-2 text-[18px]"></i>
                        <span class="text-[18px] font-medium">{{item.title}}</span>
                    </a>
                </li><!--end content-->
            </ul>
        </div>
    </div><!--end container-->
</template>

<script>
export default {
    data() {
        return {
            categories: [
                {
                    title: 'DevOps',
                    icon: 'uil uil-server',
                },
                {
                    title: 'Cloud Computing (Azure, GCP, AWS)',
                    icon: 'uil uil-cloud',
                },
                {
                    title: 'CI/CD (Jenkins, GitLab, Azure DevOps, Github)',
                    icon: 'uil uil-process',
                },
                {
                    title: 'Kubernetes & Docker & Helm',
                    icon: 'uil uil-layers',
                },
                {
                    title: 'Cybersecurity',
                    icon: 'uil uil-shield-check',
                },
                {
                    title: 'Software Development (C#, Python, Javascript)',
                    icon: 'uil uil-code-branch',
                },
                {
                    title: 'GitOps (ArgoCD, Azure CLI, Other CLIs)',
                    icon: 'uil uil-process',
                },
                {
                    title: 'Test Automation',
                    icon: 'uil uil-robot',
                },
                {
                    title: 'Server Management (Linux, Windows, ISS, Nginx)',
                    icon: 'uil uil-cloud',
                },
                {
                    title: 'Project Management',
                    icon: 'uil uil-folder-open',
                },
                {
                    title: 'Scripting (Bash, PowerShell)',
                    icon: 'uil uil-terminal',
                },
                {
                    title: 'Software Architecture',
                    icon: 'uil uil-architecture',
                },
                {
                    title: 'API Development & Management & Integration',
                    icon: 'uil uil-file-code',
                },
                {
                    title: 'Continuous Monitoring (Grafana, ELK Stack)',
                    icon: 'uil uil-chart-line',
                },
                {
                    title: 'Version Control (Git, SVN, TFS)',
                    icon: 'uil uil-gitlab',
                },
                {
                    title: 'Software Security & Data Protection (KVKK)',
                    icon: 'uil uil-shield',
                },
                {
                    title: 'Documentation & Technical Writing',
                    icon: 'uil uil-file-alt',
                },
                {
                    title: 'Infrastructure as Code (Terraform, Ansible)',
                    icon: 'uil uil-invoice',
                },
                {
                    title: 'Performance Monitoring',
                    icon: 'uil uil-signal-alt',
                },
                {
                    title: 'Quality Assurance & Testing(Sonarqube)',
                    icon: 'uil uil-check-circle',
                },
                {
                    title: 'Container Orchestration',
                    icon: 'uil uil-layer-group',
                },
                {
                    title: 'Security Best Practices',
                    icon: 'uil uil-lock-alt',
                },
                {
                    title: 'Agile Methodologies',
                    icon: 'uil uil-clock',
                },
                {
                    title: 'Tecnical Analysis & Documentation',
                    icon: 'uil uil-folder-open',
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped></style>