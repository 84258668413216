<template>
    <div class="container relative md:mt-24 mt-16">
        <div class="grid grid-cols-1 text-center">
            <span class="text-slate-400 mb-4">Available for freelance projects</span>
            <h3 class="md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Do you have designing
                project? <br> Let's talk.</h3>

            <div class="mt-6">
                <router-link to="/contact-one"
                    class="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full"><i
                        class="uil uil-phone"></i> Contact us</router-link>
            </div>
        </div><!--end grid-->
    </div><!--end container-->
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped></style>