import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: Index, // Home (index-coworking)
  },
  {
    path: "/blog/:id",
    name: "blog-detail",
    component: BlogDetail, // Blog Detail Page
  },
  {
    path: "/course-detail/:id",
    name: "course-detail",
    component:CourseDetail,
  },
  {
    path: '/consulting',
    name: 'consulting-list',
    component: ConsultingListPage,
},
{
    path: '/consulting-detail/:id',
    name: 'consulting-detail',
    component: ConsultingDetailPage,
},
    {
    path: "/training",
    name: "training",
    component: Training, // Main Training Page (course-listing)
    
  },
  {
    path: '/portfolio',
    name: 'portfolio-list',
    component: ProjectsListPage,
},
{
    path: '/project-detail/:id',
    name: 'project-detail',
    component: ProjectDetailPage,
},  
  {
    path: "/about-experience-skills",
    name: "about-experience-skills",
    component: Experince, // Main Experience and Skills Page (index-it-solution)
  },
  {
    path: "/about-academic-areas",
    name: "about-academic-areas",
    component: Academic, // Main Academic Areas Page (page-job-detail)
  },  
  {
    path: "/blog",
    name: "blog",
    component: Blog, // Main Academic Areas Page (page-job-detail)
  },  
  {
    path: "/:catchAll(.*)",
    name: "error",
    component: Error, // Error Page (page-error)
  },
];

import CourseDetail from '@/pages/course/course-detail.vue';
import ConsultingDetailPage from "@/pages/consulting/consultans.vue";
import ConsultingListPage from '@/pages//consulting/page-services.vue';
import ProjectsListPage from '@/pages/projects/projects.vue';
import ProjectDetailPage from '@/pages/projects/projectdetails.vue';
import Index from '@/pages/index-coworking.vue';
import Training from '@/pages/training/course-listing.vue';
import Experince from '@/pages/about-experience-skills/index-it-solution.vue';
import Academic from '@/pages/about-academic-areas/page-job-detail.vue';
import Error from '@/pages/error.vue';
import BlogDetail from '@/pages/blog/blog-detail.vue';
import Blog from '@/pages/blog/blog.vue';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;



/*{
    path: "/personal-projects",
    name: "personal-projects",
    component: () => import('@/pages/personal-projects/page-job-career.vue'), // Main Personal Projects Page (page-job-career)
    children: [
      {
        path: "loadtest-tool",
        name: "loadtest-tool",
        component: () => import('@/pages/personal-projects/loadtest-tool.vue'), // LoadTest Tool (page-job-candidate-detail)
      },
      {
        path: "uptime-monitoring-tool",
        name: "uptime-monitoring-tool",
        component: () => import('@/pages/personal-projects/uptime-monitoring-tool.vue'), // Web Page Uptime Monitoring Tool (page-job-candidate-detail)
      },
      {
        path: "pdf-data-processing-tool",
        name: "pdf-data-processing-tool",
        component: () => import('@/pages/personal-projects/pdf-data-processing-tool.vue'), // PDF and Data Processing Tool (page-job-candidate-detail)
      },
      {
        path: "kubernetes-troubleshooting-tool",
        name: "kubernetes-troubleshooting-tool",
        component: () => import('@/pages/personal-projects/kubernetes-troubleshooting-tool.vue'), // Kubernetes Troubleshooting Tool (page-job-candidate-detail)
      },
      {
        path: "web-scraping-tool",
        name: "web-scraping-tool",
        component: () => import('@/pages/personal-projects/web-scraping-tool.vue'), // Web Scraping Tool (page-job-candidate-detail)
      },
    ]
  },*/