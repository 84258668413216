<template>
    <!-- Footer Start -->
    <footer class="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">    
        <div class="container relative">
            <div class="grid grid-cols-12">
                <div class="col-span-12">
                    <div class="py-[60px] px-0 text-center">
                        <ul class="list-none space-y-4">
                            <li>
                                <i class="uil uil-envelope"></i> Mail: <a href="mailto:baybarshanekiz@gmail.com" class="text-gray-300 hover:text-gray-400">baybarshanekiz@gmail.com</a>
                            </li>
                            <li>
                                <i class="uil uil-linkedin"></i> LinkedIn: <a href="https://www.linkedin.com/in/baybarshan-ekiz-12b302b6/" class="text-gray-300 hover:text-gray-400">LinkedIn Profile</a>
                            </li>
                            <li>
                                <i class="uil uil-medium-m"></i> Medium: <a href="https://medium.com/@baybarshanekiz" class="text-gray-300 hover:text-gray-400">Medium Profile</a>
                            </li>
                            <li>
                                <i class="uil uil-github"></i> GitHub: <a href="https://github.com/baybarse" class="text-gray-300 hover:text-gray-400">GitHub Profile</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer End -->
</template>

<script>
export default {
    name: 'Footer',
};
</script>

<style lang="scss" scoped>
.footer {
    padding: 20px 0;
    background-color: #1a1a1a;
    color: #fff;
}
ul li {
    font-size: 18px;
}
</style>
