<template>
    <!-- Start Navbar -->
    <nav id="topnav" class="defaultscroll is-sticky bg-white dark:bg-slate-900">
        <div class="container relative">
            <!-- Logo container-->
            <router-link class="logo" to="/">
                <img src="../../assets/images/logo-dark.png" class="inline-block dark:hidden" alt="">
                <img src="../../assets/images/logo-light.png" class="hidden dark:inline-block" alt="">
            </router-link>
            <!-- End Logo container-->

            <div class="menu-extras" @click="handler">
                <div class="menu-item">
                    <!-- Mobile menu toggle-->
                    <a class="navbar-toggle" id="isToggle" :class="toggle === false ? '' : 'open'">
                        <div class="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a>
                    <!-- End mobile menu toggle-->
                </div>
            </div>

            <div id="navigation" :class="toggle === false ? 'none' : 'block'">
                <!-- Navigation Menu-->
                <ul class="navigation-menu">
                    <!-- Home -->
                    <li><router-link to="/" class="sub-menu-item">Home</router-link></li>
                     <!-- Portfolio -->
                     <li><router-link to="/portfolio" class="sub-menu-item">Portfolio</router-link></li>

                     <!-- About Dropdown -->
                    <li class="has-submenu parent-menu-item">
                        <router-link to="/about-experience-skills" @click="submenu(menuOpen === 'about' ? '' : 'about')">About <span class="menu-arrow"></span></router-link>
                        <ul class="submenu" :class="menuOpen === 'about' ? 'open' : ''" ref="dropdownToggle3">
                            <li><router-link to="/about-experience-skills" class="sub-menu-item">Experience</router-link></li>
                            <li><router-link to="/about-academic-areas" class="sub-menu-item">Academic Background and Research Areas</router-link></li>
                        </ul>
                    </li>


                    <!-- Consulting Dropdown -->
                    <li class="has-submenu parent-menu-item">
                        <router-link to="/consulting" @click="submenu(menuOpen === 'consultancy' ? '' : 'consultancy')">Consulting <span class="menu-arrow"></span></router-link>
                        <ul class="submenu" :class="menuOpen === 'consultancy' ? 'open' : ''" ref="dropdownToggle">
                            <li><router-link to="/consulting-detail/1" class="sub-menu-item">DevOps Transformation</router-link></li>
                            <li><router-link to="/consulting-detail/2" class="sub-menu-item">B2B/B2C System Development</router-link></li>
                            <li><router-link to="/consulting-detail/3" class="sub-menu-item">Website Software Development and Integration</router-link></li>
                            <li><router-link to="/consulting-detail/4" class="sub-menu-item">Desktop Software Development and Integration</router-link></li>
                            <li><router-link to="/consulting-detail/5" class="sub-menu-item">System Integration</router-link></li>
                            <li><router-link to="/consulting-detail/6" class="sub-menu-item">Project Management and Coaching</router-link></li>
                            <li><router-link to="/consulting-detail/7" class="sub-menu-item">Corporate Governance Consulting</router-link></li>
                            <li><router-link to="/consulting-detail/8" class="sub-menu-item">TEYDEB Consulting</router-link></li>
                            <li><router-link to="/consulting-detail/9" class="sub-menu-item">KVKK Compliance Consulting</router-link></li>
                        </ul>
                    </li>

                    <!-- Training Dropdown -->
                    <li class="has-submenu parent-menu-item">
                        <router-link to="/training" @click="submenu(menuOpen === 'education' ? '' : 'education')">Training <span class="menu-arrow"></span></router-link>
                        <ul class="submenu" :class="menuOpen === 'education' ? 'open' : ''" ref="dropdownToggle2">
                            <li><router-link to="/course-detail/1" class="sub-menu-item">DevOps Training</router-link></li>
                            <li><router-link to="/course-detail/2" class="sub-menu-item">Agile Training</router-link></li>
                            <li><router-link to="/course-detail/3" class="sub-menu-item">SDLC Training</router-link></li>
                            <li><router-link to="/course-detail/4" class="sub-menu-item">Git Training</router-link></li>
                            <li><router-link to="/course-detail/5" class="sub-menu-item">Docker Training</router-link></li>
                            <li><router-link to="/course-detail/6" class="sub-menu-item">CI/CD Training</router-link></li>
                            <li><router-link to="/course-detail/7" class="sub-menu-item">GitOps Training</router-link></li>
                            <li><router-link to="/course-detail/8" class="sub-menu-item">DevSecOps Training</router-link></li>
                            <li><router-link to="/course-detail/9" class="sub-menu-item">Azure Cloud Training</router-link></li>
                        </ul>
                    </li>

                   
                    
                    <li><router-link to="/blog" class="sub-menu-item">Tech Resources</router-link></li>
                </ul><!--end navigation menu-->
            </div><!--end navigation-->
        </div><!--end container-->
    </nav><!--end header-->
    <!-- End Navbar -->
</template>


<script>
import feather from 'feather-icons';
import vClickOutside from 'v-click-outside';

export default {
    directives: {
        clickOutside: vClickOutside.directive,
    },
    data() {
        return {
            toggle: false,
            scroll: true,
            sections: document.querySelectorAll("section"),
            navLi: document.querySelectorAll("nav .container .navigation ul li"),
            sectionTop: null,
            id: 1,
            searchmenu: false,
            cartmenu: false,
            account: false,
            activeMenu: '',
            menu: true,
            menuOpen: '',
            dropdownOpen: false,
            dropdownOpen2: false,
            dropdownOpen3: false,
        }
    },
    mounted() {
        feather.replace();
        this.screenTop();
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('click', this.handleClickOutside2);
        document.addEventListener('click', this.handleClickOutside3);
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        this.activeMenu = window.location.pathname;
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
        document.removeEventListener('click', this.handleClickOutside);
        document.removeEventListener('click', this.handleClickOutside2);
        document.removeEventListener('click', this.handleClickOutside3);
    },
    methods: {
        handler() {
            this.toggle = !this.toggle;
        },
        submenu(item) {
            this.menu = !this.menu;
            this.menuOpen = item;
        },
        handleClickOutside(event) {
            if (this.$refs.dropdownToggle && !this.$refs.dropdownToggle.contains(event.target)) {
                this.dropdownOpen = false;
            }
        },
        handleClickOutside2(event) {
            if (this.$refs.dropdownToggle2 && !this.$refs.dropdownToggle2.contains(event.target)) {
                this.dropdownOpen2 = false;
            }
        },
        handleClickOutside3(event) {
            if (this.$refs.dropdownToggle3 && !this.$refs.dropdownToggle3.contains(event.target)) {
                this.dropdownOpen3 = false;
            }
        },
        handleScroll() {
            const navbar = document.getElementById("topnav");
            if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
                navbar.classList.add("nav-sticky");
            } else {
                navbar.classList.remove("nav-sticky");
            }
        },
        screenTop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    },
}
</script>


<style lang="scss" scoped></style>
