<template>
   

    <div style="height: 100px;"></div>
    
    <div class="grid grid-cols-1 pb-8 text-center">
        <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Featured portfolios.</h3>

        <p class="text-slate-400 max-w-xl mx-auto">Some of the projects I have meticulously worked on</p>
    </div><!--end grid-->
    <div class="grid grid-cols-1 pb-8 text-center">
        <router-link to="/portfolio" class="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">See All Portfolio</router-link>
    </div><!--end grid-->
    <!-- Start Section-->
    <section class="relative md:py-24 py-16">
        <div class="container relative">
          

            <div class="grid mx-auto lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 mt-4 gap-[30px]">
                <div v-for="item in filteredData" :key="item.id" class="picture-item">
                    <div class="group relative block overflow-hidden rounded-md duration-500">
                        <router-link :to="{ name: 'project-detail', params: { id: item.id } }"><img :src="item.image" class="rounded-md" alt=""></router-link>
                        <div class="content pt-3">
                            <h5 class="mb-1"><router-link :to="{ name: 'project-detail', params: { id: item.id } }" class="hover:text-indigo-600 duration-500 font-semibold">{{item.title}}</router-link></h5>
                            <h6 class="text-slate-400">{{item.name}}</h6>
                            <p class="text-slate-400 mt-2">{{item.desc}}</p>
                        </div>
                    </div>
                </div>
            </div>

           
        </div><!--end container-->
       
    </section><!--end section-->
    <!-- End Section-->
   <!-- <Footer :email=true /> -->
</template>

<script>

import image1 from '/src/assets/images/portfolio/hmb.png';
import image2 from '/src/assets/images/portfolio/ing.png';
import image3 from '/src/assets/images/portfolio/bilyoner.png';
import image4 from '/src/assets/images/portfolio/vakıfbank.png';
import image5 from '/src/assets/images/portfolio/operators.png';// Other image imports...

export default {
    data() {
        return {
            selectedCategory: null,
            datas: [
                {
                    id: 1,
                    image: image1,
                    name: 'Ministry of Treasury and Finance',
                    title: 'DevOps Transformation Project',
                    category: 'development',
                    desc: 'We optimized the software development processes within the ministry using modern DevOps methodologies.'
                },
                {
                    id: 3,
                    image: image2,
                    name: 'ING Group Turkey',
                    title: 'B2B/B2C Azure Cloud and DevOps Project',
                    category: 'development',
                    desc: 'We ensured scalability and security by migrating ING’s existing systems to the Azure Cloud environment.'
                },
                {
                    id: 4,
                    image: image3,
                    name: 'Bilyoner',
                    title: 'Amazon Cloud Transformation Project',
                    category: 'development',
                    desc: 'We ensured performance and reliability by migrating Bilyoner’s high-traffic platform to AWS cloud.'
                },
                {
                    id: 5,
                    image: image4,
                    name: 'Vakıfbank',
                    title: 'DevOps and ItOps Transformation Project',
                    category: 'development',
                    desc: 'We restructured Vakıfbank’s software development and operations processes.'
                },
                {
                    id: 2,
                    image: image5,
                    name: 'Turkcell, Türk Telekom, Vodafone',
                    title: 'Joint Data Storage System Project',
                    category: 'development',
                    desc: 'We created a centralized data management platform to securely store the data of telecom operators.'
                },

            ],
            filteredData: this.datas,
        };
    },
    components: {
    },
    created() {
        this.matchCategory(null); // Show all projects initially
    },
    methods: {
        matchCategory(category) {
            this.selectedCategory = category;
            this.filteredData = this.selectedCategory
                ? this.datas.filter(item => item.category === this.selectedCategory)
                : this.datas;
        },
    },
};
</script>

<style lang="scss" scoped></style>
