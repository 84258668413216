<template>
    <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
        <div v-for="item in datas" :key="item.id" class="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
            <div class="relative overflow-hidden text-transparent -m-3">
                <i :data-feather="item.icon" class="size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"></i>
                <div class="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                    <i :class="item.icon2"></i>
                </div>
            </div>

            <div class="mt-6">
                <router-link :to="{ name: 'consulting-detail', params: { id: item.id } }" class="text-lg font-medium group-hover:text-white duration-500">
                    {{ item.title }}
                </router-link>
                <p class="text-slate-400 group-hover:text-white/50 duration-500 mt-3">{{ item.desc }}</p>
            </div>
        </div><!--end feature-->
    </div>
</template>

<script>
export default {
    data() {
    return {
        datas: [
            {
                id: 1,
                icon: 'hexagon',
                icon2: 'uil uil-chart-line',
                title: 'DevOps Transformation',
                desc: 'Increase software quality and accelerate the process with DevOps.'
            },
            {
                id: 2,
                icon: 'hexagon',
                icon2: 'uil uil-crosshairs',
                title: 'B2B/B2C System Development',
                desc: 'Optimize your business processes with custom B2B/B2C solutions.'
            },
            {
                id: 3,
                icon: 'hexagon',
                icon2: 'uil uil-airplay',
                title: 'Web Software Development and Integration',
                desc: 'Strengthen and integrate your digital presence with web solutions.'
            },
            {
                id: 4,
                icon: 'hexagon',
                icon2: 'uil uil-rocket',
                title: 'Desktop Software Development and Integration',
                desc: 'Enhance your business processes with desktop software.'
            },
            // Other consulting services
            {
                id: 5,
                icon: 'hexagon',
                icon2: 'uil uil-gear',
                title: 'System Integration',
                desc: 'Increase operational efficiency by integrating different systems.'
            },
            {
                id: 6,
                icon: 'hexagon',
                icon2: 'uil uil-chart-pie-alt',
                title: 'Project Management and Coaching',
                desc: 'Improve your success rate with project management and coaching.'
            },
            {
                id: 7,
                icon: 'hexagon',
                icon2: 'uil uil-building',
                title: 'Corporate Governance Consulting',
                desc: 'Strengthen your organization by optimizing governance processes.'
            },
            {
                id: 8,
                icon: 'hexagon',
                icon2: 'uil uil-lightbulb-alt',
                title: 'TEYDEB Consulting',
                desc: 'Get consulting to successfully manage your TEYDEB projects.'
            },
            {
                id: 9,
                icon: 'hexagon',
                icon2: 'uil uil-shield-check',
                title: 'KVKK Compliance Consulting',
                desc: 'Minimize legal risks with KVKK compliance.'
            }
        ]
    }
}
}
</script>

<style lang="scss" scoped></style>
