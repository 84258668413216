<template>
   <navbar :navLight="'nav-light'" />
     <!-- Start Hero -->
     <section class="relative table w-full py-36 lg:py-44 bg-[url('/src/assets/images/job/job.jpg')] bg-no-repeat bg-center bg-cover">
            <div class="absolute inset-0 bg-black opacity-80"></div>
            <div class="container relative">
                <div class="grid grid-cols-1 pb-8 text-center mt-12">
                    <h3 class="mb-4 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">{{data?.name ? data?.name : 'Education Details'}}</h3>

                    <ul class="list-none">
                        <li class="inline text-slate-400 me-3"><i class="uil uil-map-marker text-white h6 me-1"></i> {{data?.location}}</li>
                    </ul>
                </div><!--end grid-->
            </div><!--end container-->

            <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                <ul class="tracking-[0.5px] mb-0 inline-block">
                   <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><router-link to="/">Home</router-link></li>
                   <li class="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="uil uil-angle-right-b"></i></li>
                   <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"><router-link to="/index-education">Education</router-link></li>
                </ul>
            </div>
        </section><!--end section-->
        <div class="relative">
            <div class="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden text-white dark:text-slate-900">
                <svg class="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <!-- End Hero -->

        <!-- Start Section-->
        <section class="relative md:mt-24 mt-16">
            <div class="container relative">
                <div class="grid lg:grid-cols-12 grid-cols-1" id="education-details">
                    <div class="lg:col-start-2 lg:col-span-10">
                        <h5 class="mb-4 font-medium text-xl">Education Background:</h5>

                        <p class="text-slate-400 mb-4">I have pursued an extensive education in fields related to computer science, digital forensic engineering, and software engineering. Below is a summary of my academic achievements and the institutions I attended:</p>

                        <h5 class="mb-4 mt-6 font-medium text-xl">Degrees:</h5>

                        <ul class="list-none mb-0">
                            <li v-for="degree in degrees" :key="degree" class="text-slate-400 flex mt-2"><i class="uil uil-arrow-right text-indigo-600 h5 mb-0 me-2"></i> {{ degree }}</li>
                        </ul>

                        <h5 class="mb-4 mt-6 font-medium text-xl">Skills Acquired</h5>
                        
                        <ul class="list-none mb-0">
                            <li v-for="skill in skills" :key="skill" class="text-slate-400 flex mt-2"><i class="uil uil-arrow-right text-indigo-600 h5 mb-0 me-2"></i> {{ skill }}</li>
                        </ul>

                        <h5 class="mb-4 mt-6 font-medium text-xl">Certifications</h5>
                        
                        <ul class="list-none mb-0">                        
                            <li v-for="certification in certifications" :key="certification" class="text-slate-400 flex mt-2"><i class="uil uil-arrow-right text-indigo-600 h5 mb-0 me-2"></i> {{ certification }}</li>
                        </ul>

                        
                    </div>
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End Section-->
        
        <Footer/>
        <switcher />

</template>
<script>
import navbar from '@/components/navbar/navbar.vue';
import Footer from '@/components/footer/footer.vue';
import switcher from '@/components/switcher.vue'
export default {
    data() {
        return {
            degrees: [
                'PhD in Computer Science, Altınbaş University, 2022 - Present',
                'Master’s Degree in Digital Forensic Engineering, Fırat University, 2018 - 2021',
                'Bachelor’s Degree in Digital Forensic Engineering, Fırat University, 2013 - 2018',
                'Bachelor’s Degree in Software Engineering, Fırat University, 2013 - 2018',
                'High School Diploma in Web Programming, Erkan Avcı Technical Highschool, 2009 - 2013',
            ],
            skills: [
                'Agile Methodologies and Scrum',
                'Software Development in C# and Python',
                'Digital Forensics and Cybersecurity',
                'Database and Algorithm Optimization',
                'Artificial Intelligence and Data Mining',
                'Web Technologies and Software Architectures',
            ],
            certifications: [
                'Certified Kubernetes Administrator (CKA), ID: LF-0ld2qvsieo',
                'Various online certifications in cloud computing and cybersecurity',
            ],
            data: '',
            id: ''
        };
    },
    components: {
        navbar,        
        Footer,
        switcher
    },
    mounted() {
        this.id = this.$route.params.id;
        this.data = this.degrees.find((item) => item.id === parseInt(this.id));
    },
};
</script>
<style lang="scss" scoped>

</style>