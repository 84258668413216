<template>
    <navbar :navLight="'nav-light'" />
    <!-- Start Hero -->
    <section
      class="relative table w-full py-36 lg:py-44 bg-[url('/src/assets/images/job/job.jpg')] bg-no-repeat bg-center bg-cover"
    >
      <div class="absolute inset-0 bg-black opacity-80"></div>
      <div class="container relative">
        <div class="grid grid-cols-1 pb-8 text-center mt-12">
          <h3 class="mb-4 md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
            {{ data?.title ? data?.title : 'Consulting Service' }}
          </h3>
        </div>
      </div>

      <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="tracking-[0.5px] mb-0 inline-block">
          <li
            class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"
          >
            <router-link to="/">Techwind</router-link>
          </li>
          <li
            class="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"
          >
            <i class="uil uil-angle-right-b"></i>
          </li>
          <li
            class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"
          >
            <router-link to="/consulting">Consulting</router-link>
          </li>
          <li
            class="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"
          >
            <i class="uil uil-angle-right-b"></i>
          </li>
          <li
            class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
            aria-current="page"
          >
            {{ data?.title }}
          </li>
        </ul>
      </div>
    </section>
    <div class="relative">
      <div
        class="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden text-white dark:text-slate-900"
      >
        <svg
          class="w-full h-auto scale-[2.0] origin-top"
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- End Hero -->

    <!-- Start Section-->
    <section class="relative md:mt-24 mt-16">
      <div class="container relative">
        <div class="grid lg:grid-cols-12 grid-cols-1">
          <div class="lg:col-start-2 lg:col-span-10">
            <h5 class="mb-4 font-medium text-xl">Description:</h5>
            <p class="text-slate-400 mb-4">{{ data?.desc }}</p>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section-->
    <Footer/>
    <switcher />
</template>

<script>
import navbar from '@/components/navbar/navbar.vue';
import Footer from '@/components/footer/footer.vue';
import switcher from '@/components/switcher.vue';

export default {
  data() {
    return {
        isActive: false,
      data: '', // Consulting data
      id: '', // Consulting ID
      title: '',
      desc: '',
      datas: [
  {
    id: 1,
    title: 'DevOps Transformation',
    desc: `DevOps is a cultural and technical approach that unifies software development (Dev) and IT operations (Ops) to enhance collaboration, increase efficiency, and accelerate the software delivery process. This service focuses on integrating the core principles of DevOps into your company culture, enabling you to establish Continuous Integration (CI), Continuous Delivery (CD), and automation processes tailored to your business needs. Our goal is to enhance software quality, ensure security, and reduce time to market.

    Throughout the transformation process, we conduct a thorough assessment of your current infrastructure, identify optimization opportunities, and implement DevOps tools and best practices. By the end of the transformation, your organization will experience faster and more efficient software development and deployment cycles, positioning you ahead of the competition in today’s digital marketplace.`,
  },
  {
    id: 2,
    title: 'B2B/B2C System Development',
    desc: `B2B (business-to-business) and B2C (business-to-customer) systems are foundational elements of digital commerce, facilitating seamless transactions and interactions between businesses and their clients. Our service offers custom-designed B2B/B2C solutions that are tailored to your specific business needs. This includes developing e-commerce platforms, customer relationship management (CRM) systems, supply chain management software, and integrated payment systems.

    Our primary objective is to automate your business processes to enhance operational efficiency and maximize customer satisfaction. We select the most suitable technological infrastructure for your requirements, ensuring that the systems we develop are scalable, secure, and able to support your long-term business goals.`,
  },
  {
    id: 3,
    title: 'Web Software Development and Integration',
    desc: `To strengthen your business’s digital presence, we provide customized web software solutions that are designed to meet your specific needs. This service includes the development of web-based applications, content management systems (CMS), e-commerce platforms, and customer portals. In addition to creating new solutions, we integrate existing systems with new software to optimize operational alignment and data flow.

    Our development process emphasizes user-friendly interfaces and modern design principles, ensuring that the software we build adds significant value to both your customers and your business. We also offer continuous maintenance and support services to guarantee the performance and security of your software.`,
  },
  {
    id: 4,
    title: 'Desktop Software Development and Integration',
    desc: `We offer desktop software solutions that are designed to enhance your business processes, tailored specifically to your organizational needs. Our custom desktop applications provide high-performance solutions in critical areas such as data management, task tracking, and customer service. In addition to developing new applications, we also integrate your existing software with new technologies to minimize disruptions in your business processes.

    Our solutions are focused on performance, security, and user experience, ensuring that your business’s digital transformation is both effective and efficient. Whether you need a single application or a comprehensive suite of integrated tools, our team is equipped to deliver robust solutions that support your long-term goals.`,
  },
  {
    id: 5,
    title: 'System Integration',
    desc: `Our system integration service ensures that the various systems within your organization work seamlessly together, increasing operational efficiency. By integrating different software and hardware components, we create a unified and coherent system that supports your business processes. This service includes the integration of ERP (Enterprise Resource Planning) systems, CRM (Customer Relationship Management) tools, data analytics platforms, and other business applications.

    The result is optimized data flow and streamlined business processes that operate more quickly, efficiently, and reliably. We ensure that all systems work together harmoniously, reducing the complexity of your IT environment and enabling your business to function at its highest potential.`,
  },
  {
    id: 6,
    title: 'Project Management and Coaching',
    desc: `To ensure the successful completion of your projects, we offer comprehensive project management and coaching services. Our expert project managers are dedicated to delivering your projects on time, within budget, and to the highest quality standards. In addition to managing projects, we provide coaching to your project teams, helping to develop their project management skills.

    We guide you through every phase of your projects, offering support in areas such as risk management, resource planning, and process improvement. Our goal is to guarantee the success of your projects by providing the expertise and guidance needed to navigate the complexities of project management.`,
  },
  {
    id: 7,
    title: 'Corporate Governance Consulting',
    desc: `To support the sustainable growth and long-term success of your organization, we optimize your corporate governance processes. This service evaluates your internal governance structures, improves existing processes, and develops new strategies to enhance your organization's governance framework. We offer solutions in areas such as corporate risk management, compliance processes, decision-making mechanisms, and the effectiveness of board structures.

    Our goal is to strengthen your corporate culture, increase transparency, and create a sustainable governance structure that aligns with your organization's strategic objectives. By improving your governance processes, we help you build a more resilient and agile organization capable of navigating today’s complex business environment.`,
  },
  {
    id: 8,
    title: 'TEYDEB Consultancy',
    desc: `We offer consultancy services to help ensure the successful execution of your R&D and innovation projects, maximizing your benefits from TEYDEB (Technology and Innovation Funding Programs Directorate) support. This service covers all stages of your projects, from preparing project proposals to managing the project and reporting.

    We develop solutions that are fully compliant with TEYDEB regulations, optimizing your project goals and outcomes. Our objective is to increase the success rate of your R&D projects and enhance your innovation capabilities, positioning your organization as a leader in technological advancement.`,
  },
  {
    id: 9,
    title: 'KVKK Corporate Compliance Consultancy',
    desc: `Our consultancy service ensures full compliance with the Turkish Personal Data Protection Law (KVKK), protecting your business from legal risks. We analyze your current data processing practices, implement necessary adjustments, and develop new policies to ensure compliance with KVKK.

    Additionally, we provide KVKK training to your employees to raise awareness within your organization. Our goal is to safeguard customer trust and guarantee legal compliance, allowing you to focus on growing your business without the worry of regulatory issues.`,
  }
],
    };
  },
  components: {
    navbar,
    Footer,
    switcher,
  },
  mounted() {
    this.id = this.$route.params.id;
    console.log('Mounted with ID:', this.id); // Debugging log
    this.fetchData();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    console.log('Route updated:', to.params.id); // Debugging log
    this.fetchData();
    next();
  },
  methods: {
    fetchData() {
      this.data = this.datas.find((item) => item.id === parseInt(this.id));
      console.log('Fetched data:', this.data); // Debugging log
    },
    toggle() {
      this.isActive = !this.isActive;
    }
  },
};
</script>

<style lang="scss" scoped></style>