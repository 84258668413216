<template>
    <navbar :navLight="'nav-light'" />
    <!-- Start Hero -->
    <section class="relative md:py-60 py-36 items-center">
        <div class="absolute top-0 start-0 w-full h-full z-0 pointer-events-none overflow-hidden">
            <!-- <iframe src="https://player.vimeo.com/video/33955001?background=1&autoplay=1&loop=1&byline=0&title=0" class="absolute top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2  -translate-y-1/2 w-screen h-[56.25vw] min-h-screen min-w-[177.77vw]"></iframe> -->
            <!--Note: Vimeo Embed Background Video-->

            <iframe src="https://www.youtube.com/embed/JsNvHJsufhI?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1"
                class="absolute top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2  -translate-y-1/2 w-screen h-[56.25vw] min-h-screen min-w-[177.77vw]"></iframe>
            <!--Note: Youtube Embed Background Video-->
        </div>
        <div
            class="absolute inset-0 opacity-20 bg-[url('/src/assets/images/map.svg')] bg-no-repeat bg-center bg-cover">
        </div>
        <div class="absolute inset-0 bg-black/70"></div>

        <div class="container relative">
            <div class="grid grid-cols-1 pb-8 text-center mt-10">
              
                <h3 class="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 mt-10 text-white">
                    Transforming Operations, Empowering Innovation.</h3>

                <p class="text-slate-300 text-lg max-w-2xl mx-auto">As a seasoned DevOps Engineer, I specialize in optimizing infrastructures, automating workflows, and driving digital transformation across industries.</p>

               
            </div><!--end grid-->
        </div><!--end container-->
    </section><!--end section-->
    <!-- End Hero -->

   
   <!-- <effective :Effective=true :History=true /> -->

   

    <projects /> 
   
    
    

    <categories />

    <div style="height: 100px;"></div>

   <!-- <blogs />  Bu bölgeye en önemli projeler koyulcak -->
   <experience />
   

    <div style="height: 100px;"></div>

    

   

    <div class="container relative">
        <div class="grid md:grid-cols-2 grid-cols-1 pb-8 items-center">
            <div>
                <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">Consultancy</h6>
                <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Your Partner in Digital Transformation.</h3>
            </div>
            <p class="text-slate-400 max-w-xl">I offer expert consulting services to help businesses streamline their operations, enhance security, and drive innovation. With tailored B2B/B2C solutions, I ensure that your systems are optimized and ready for the future. Let’s work together to turn challenges into opportunities and transform your digital landscape.</p>
            <div class="grid grid-cols-1 pb-8 text-center">
                <router-link to="/consulting" class="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">See All Consultancy</router-link>
            </div><!--end grid-->
        </div><!--end grid-->
        <whatwedogeneralconsult />
    </div>


    <div style="height: 100px;"></div>



    <div class="container relative">
        <div class="grid md:grid-cols-2 grid-cols-1 pb-8 items-center">
            <div>
                <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">Training</h6>
                <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Master the Future of DevOps.</h3>
            </div>
            <p class="text-slate-400 max-w-xl">My training programs are designed to equip professionals with the skills needed to excel in today’s fast-evolving tech environment. From foundational principles of DevOps and Agile to advanced CI/CD automation and cloud orchestration, I provide comprehensive education that empowers individuals and teams to lead with confidence. Join me to stay ahead of the curve and drive your career forward.</p>
            <div class="grid grid-cols-1 pb-8 text-center">
                <router-link to="/training" class="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">See All Training</router-link>
            </div><!--end grid-->
        </div><!--end grid-->
        <whatwedogeneraltrain />
    </div>



   


   
   
   

<switcher />
<Footer/>
<cookies />
</template>

<script>
import navbar from '@/components/navbar/navbar.vue';
//import effective from '@/components/powerful-digitalization.vue';
import whatwedogeneraltrain from '@/components/what-we-do/what-we-do-it-solution-general-train.vue';
import whatwedogeneralconsult from '@/components/what-we-do/what-we-do-it-solution-general-consult.vue';
import experience from '@/components/personal/work-experience.vue';
import categories from '@/components/podcart/podcart-categories.vue';
//import blogs from '@/components/blogs/blogs.vue';
import projects from '@/components/portfolio/projects-component-mainpage.vue';
import Footer from '@/components/footer/footer.vue';
import switcher from '@/components/switcher.vue';
import cookies from '@/components/start-cookies.vue'
export default {
    data() {
        return {

        }
    },
    components: {
        navbar,
        //effective,
        //blogs,
        Footer,
        switcher,
        experience,
        categories,
        whatwedogeneraltrain,
        whatwedogeneralconsult,
        projects,
        cookies
    }
}
</script>

<style lang="scss" scoped></style>