<template>
    <navbar :navLight="'nav-light'" />
    <!-- Start Hero -->
    <section class="relative table w-full py-32 lg:py-40 bg-[url('/src/assets/images/portfolio/bg-inner.jpg')] bg-no-repeat bg-center bg-cover">
        <div class="absolute inset-0 bg-gradient-to-b from-black/60 via-black/80 to-black"></div>
        <div class="container relative">
            <div class="grid grid-cols-1 pb-8 text-center mt-10">
                <h3 class="mb-3 text-3xl leading-normal font-medium text-white">Portfolio & Works</h3>
            </div><!--end grid-->
        </div><!--end container-->
       
    </section><!--end section-->
    <div class="relative">
        <div class="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
            <svg class="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!-- End Hero -->


    <switcher />

    <projects /> 
    <!-- End Section-->
    <footers/>
    <switcher />
</template>

<script>
import navbar from '@/components/navbar/navbar.vue';
import footers from '@/components/footer/footer.vue';
import switcher from '@/components/switcher.vue';
import projects from '@/components/portfolio/projects-component.vue';
// Other image imports...

export default {
    data() {

    },
    components: {
        navbar,
        footers,
        switcher,
        projects,
    },
    
};
</script>

<style lang="scss" scoped></style>
