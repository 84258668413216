<template>
    <navbar :navLight="'nav-light'" />
    <!-- Start Hero -->
    <section class="relative table w-full py-32 lg:py-36 bg-[url('/src/assets/images/blog/bg.jpg')] bg-center bg-no-repeat bg-cover">
        <div class="absolute inset-0 bg-black opacity-80"></div>
        <div class="container relative">
            <div class="grid grid-cols-1 pb-8 text-center mt-10">
                <h3 class="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">Sources</h3>
            </div>
        </div>

       
    </section>

    <div class="relative">
        <div class="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
            <svg class="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>

    <!-- Start Section-->
    <section class="relative md:py-24 py-16">
        <div class="container relative">
            <!-- Search Bar -->
            <div class="grid grid-cols-1 pb-8 text-center">
                <input v-model="searchQuery" placeholder="Search by title..." class="py-3 px-4 w-full mb-6 rounded-md shadow dark:shadow-gray-800 border dark:border-gray-700 text-black dark:text-white">
            </div>

            <div class="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-[30px]">
                <div v-for="item in filteredBlogs" :key="item.id" @click="viewBlogDetail(item.id)"
                    class="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                    <img :src="item.image" alt="">

                    <div class="content p-6">
                        <a :href="item.link" target="_blank">{{ item.title }}</a>
                        <p class="text-slate-400 mt-3">{{ item.desc }}</p>

                        <div class="mt-4">
                            <a :href="item.link" target="_blank">{{ item.title }}</a>
                        </div>
                    </div>
                </div>
            </div>

           
        </div>
    </section>
    <Footer/>
    <switcher />
</template>


<script>

import navbar from '@/components/navbar/navbar.vue';
import Footer from '@/components/footer/footer.vue';
import switcher from '@/components/switcher.vue'

export default {
    data() {
        return {
            searchQuery: '', // Add search query
            datas: [
  {
    id: 1,
    image: require('/src/assets/images/blog/cloud.png'),
    title: 'KVKK Administrative and Technical Measures Checklist',
    desc: 'Comprehensive checklist for compliance with KVKK (Turkish Data Protection Law)',
    link: 'https://media.licdn.com/dms/document/media/C4D1FAQFOwpYKbngC_A/feedshare-document-pdf-analyzed/0/1620505006675?e=1726704000&v=beta&t=msl9A3cdJIOK1Hq-23la0aqeyQw7i5411hc8wwcRay4'
  },
  {
    id: 2,
    image: require('/src/assets/images/blog/git.png'),
    title: 'Linux Beginner’s Guide: 21 Essential Topics',
    desc: 'A valuable document covering installation, file management, user management, logging, and more for Linux newcomers.',
    link: 'https://media.licdn.com/dms/document/media/C4D1FAQFYzHWVgqgLWQ/feedshare-document-pdf-analyzed/0/1624299600806?e=1726704000&v=beta&t=ADWn2kbv2M2fnujMxJ5CZquGg1_bc0Oc21TPjVec0tU'
  },
  {
    id: 3,
    image: require('/src/assets/images/blog/kubernetes.png'),
    title: 'Zabbix Installation Guide',
    desc: 'Detailed guide for installing Zabbix, adding hosts, creating alarms, and performing updates.',
    link: 'https://media.licdn.com/dms/document/media/C561FAQE_Fx1jqfsatQ/feedshare-document-pdf-analyzed/0/1642268379792?e=1726704000&v=beta&t=qOOVzSaiB9bAYgdeV3Li6UzckvDN42T5BtMVLevHy0I'
  },
  {
    id: 4,
    image: require('/src/assets/images/blog/security.png'),
    title: 'Zero Trust for Microsoft Identity Platform',
    desc: 'Overview of how to implement Zero Trust Security in Microsoft Azure environments.',
    link: 'https://media.licdn.com/dms/document/media/C4E1FAQFV4NWy1aNM4g/feedshare-document-pdf-analyzed/0/1644390612599?e=1726704000&v=beta&t=YEQaja3O9u_ilVtjgSaQHeR6HqG-ZL1mnufXZvU7NX4'
  },
  {
    id: 5,
    image: require('/src/assets/images/blog/dashboard.png'),
    title: 'DevSecOps: Achieving Fast and Secure Deployments',
    desc: 'Learn how to integrate security into fast-paced deployments using DevSecOps principles.',
    link: 'https://media.licdn.com/dms/document/media/C561FAQEDY-c-zs_h3w/feedshare-document-pdf-analyzed/0/1658064104556?e=1726704000&v=beta&t=9F4jS-dh45lhOCbkU9IB9QqRohFw98OommU6bDjMsTA'
  },
  {
    id: 6,
    image: require('/src/assets/images/blog/persons.png'),
    title: 'GitHub Mastery Simplified: Ultimate GitHub Cheatsheet',
    desc: 'A quick reference guide for essential GitHub commands and productivity tips.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQEeR8EiWtquzw/feedshare-document-pdf-analyzed/0/1723530088487?e=1726704000&v=beta&t=h0KJhI_2fMrIFqsRM_ZIiUrY7veY_BtYObSJnlW1ZX4'
  },
  {
    id: 7,
    image: require('/src/assets/images/blog/api.png'),
    title: 'Boost Your Infrastructure as Code Skills with Terraform',
    desc: 'Enhance your Terraform knowledge with practical tips and techniques for better IaC.',
    link: 'https://media.licdn.com/dms/document/media/D4D1FAQGo3fskaeT6_g/feedshare-document-pdf-analyzed/0/1723606316828?e=1726704000&v=beta&t=Jkl4YksH7MQ_aI_P5347UQU9m4qdeCn5ASz9vIbQP6M'
  },
  {
    id: 8,
    image: require('/src/assets/images/blog/cloud.png'),
    title: 'Boost Your Docker Skills with Essential Tips',
    desc: 'Improve your Docker skills with these key insights on containerization and deployment.',
    link: 'https://media.licdn.com/dms/document/media/D4D1FAQES_8TlQCHUdQ/feedshare-document-pdf-analyzed/0/1723876216147?e=1726704000&v=beta&t=jGQIIfbXuSC7mrbTFvzYwhzHR8h3Xg2cqZ15exg9Kqo'
  },
  {
    id: 9,
    image: require('/src/assets/images/blog/git.png'),
    title: '2024 DevOps Toolkit',
    desc: 'A must-read toolkit covering essential tools and practices for modern DevOps teams.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQGdwjMCyK9iOA/feedshare-document-pdf-analyzed/0/1723889121056?e=1726704000&v=beta&t=FHohWRHN3F3H7PCoJJ8qHepxdbhzaK2BzdG3II-FJNs'
  },
  {
    id: 10,
    image: require('/src/assets/images/blog/kubernetes.png'),
    title: 'Top Linux Command Cheatsheet for Everyone',
    desc: 'A concise and helpful guide to mastering essential Linux commands.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQH1I1DmJ2ZtxQ/feedshare-document-pdf-analyzed/0/1723975704911?e=1726704000&v=beta&t=7L0v8dfwswhi5XIBOhfUIEu2r28-Ao2alC3LPJfsxu8'
  },
  {
    id: 11,
    image: require('/src/assets/images/blog/security.png'),
    title: 'Kubernetes Commands Cheatsheet',
    desc: 'A quick reference guide to the most important Kubernetes commands.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQEsk5ZEsYNzFQ/feedshare-document-pdf-analyzed/0/1724839364964?e=1726704000&v=beta&t=RNvapt3wV6ylgVFx5ZsandG0nmflmqJb1EYOMf4l1FU'
  },
  {
    id: 12,
    image: require('/src/assets/images/blog/dashboard.png'),
    title: 'Python Basics Book',
    desc: 'A helpful book for beginners to grasp the core concepts of Python programming.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQGzgCjk4DmQHA/feedshare-document-pdf-analyzed/0/1724842536662?e=1726704000&v=beta&t=otO43fAPc07XS6D4ujiU3EkJYskOvAvupoWtdCXqa4E'
  },
  {
    id: 13,
    image: require('/src/assets/images/blog/cloud.png'),
    title: 'Linux Commands Handbook',
    desc: 'A comprehensive guide to essential Linux commands for system administrators.',
    link: 'https://media.licdn.com/dms/document/media/D4D1FAQGXifRNxBS3aQ/feedshare-document-pdf-analyzed/0/1724982816738?e=1726704000&v=beta&t=H228mEMb4FzjEeDtOucXjIRDGBthVgbnaMMJGjUicWU'
  },
  {
    id: 14,
    image: require('/src/assets/images/blog/git.png'),
    title: 'Nmap Host Discovery Cheat Sheet',
    desc: 'A valuable resource for understanding and utilizing Nmap for host discovery and network scanning.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQHxHX8D4eYy6A/feedshare-document-pdf-analyzed/0/1725202271480?e=1726704000&v=beta&t=TVxz8vRwBtpoq8c0DHx98JrooGrcT6y9Vd7202jQVF4'
  },
  {
    id: 15,
    image: require('/src/assets/images/blog/api.png'),
    title: 'AWS CloudFormation Guide',
    desc: 'A practical guide to mastering AWS CloudFormation for infrastructure automation.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQG3CMTaGK41OQ/feedshare-document-pdf-analyzed/0/1725483259601?e=1726704000&v=beta&t=TjnDq_zPlnFVhLzTIyvjYRv2kSfDEaL4l8xSExDTVtQ'
  },
  {
    id: 16,
    image: require('/src/assets/images/blog/kubernetes.png'),
    title: 'Linux Networking Commands',
    desc: 'A guide to essential Linux networking commands, perfect for system admins and network engineers.',
    link: 'https://media.licdn.com/dms/document/media/D4E1FAQGvzP4AmKYzyA/feedshare-document-pdf-analyzed/0/1725537649438?e=1726704000&v=beta&t=xh5HI6KkEOGT0eOPF4RUrrjehxGyjSPBNCIfKAd8M54'
  },
  {
    id: 17,
    image: require('/src/assets/images/blog/security.png'),
    title: 'Git Cheat Sheet',
    desc: 'Quick reference for mastering Git commands and improving version control workflows.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQHmsKQZjGvgHQ/feedshare-document-pdf-analyzed/0/1725600373894?e=1726704000&v=beta&t=GCQD0aGztDu2FuuhkXKWl5-e-7L1N-fm4iDGPt4_HoU'
  },
  {
    id: 18,
    image: require('/src/assets/images/blog/persons.png'),
    title: 'Prometheus Monitoring Guide',
    desc: 'Comprehensive guide for setting up and using Prometheus for system and service monitoring.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQF2qfPb37p-uw/feedshare-document-pdf-analyzed/0/1725377582457?e=1726704000&v=beta&t=J2VvYN-EcPbV_v235RN69YAacoLT1VFBmS_Uw1NWE78'
  },
  {
    id: 19,
    image: require('/src/assets/images/blog/dashboard.png'),
    title: 'OSINT Cheat-Sheet',
    desc: 'A quick guide to the best tools and techniques for open-source intelligence gathering (OSINT).',
    link: 'https://media.licdn.com/dms/document/media/D4D1FAQFVg9JGe6oV0g/feedshare-document-pdf-analyzed/0/1724313102022?e=1726704000&v=beta&t=Fx-WwvrjP5-nlzFfpzhCvC2FAx2n4vtT4nuYR8LFKwI'
  },
  {
    id: 20,
    image: require('/src/assets/images/blog/git.png'),
    title: 'Docker Cheatsheet',
    desc: 'Essential Docker commands and tips to help streamline your containerization efforts.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQHQ_4t_Twf6Gw/feedshare-document-pdf-analyzed/0/1724500131130?e=1726704000&v=beta&t=vyethJAB9adqPEvQsJ4037wXH1jkVFqpMVpe6u8rtYU'
  },
  {
    id: 21,
    image: require('/src/assets/images/blog/kubernetes.png'),
    title: 'BASH Scripting Guide',
    desc: 'A detailed guide for mastering BASH scripting, covering everything from basic to advanced topics.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQFYxUrWj4gf7g/feedshare-document-pdf-analyzed/0/1724485774064?e=1726704000&v=beta&t=pAPz-KribqywHBLaKyGdGLujWju6NBn74teRKVr_eEI'
  },
  {
    id: 22,
    image: require('/src/assets/images/blog/cloud.png'),
    title: 'CI/CD with Docker and Kubernetes',
    desc: 'Learn how to implement CI/CD pipelines using Docker and Kubernetes for modern applications.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQG4lIAjV5iKOw/feedshare-document-pdf-analyzed/0/1724641820012?e=1726704000&v=beta&t=3MyNsad-E28cK0MrTkuZVfGaYnnivFa9fTY2HF_UK7g'
  },
  {
    id: 23,
    image: require('/src/assets/images/blog/dashboard.png'),
    title: 'Practical DevOps',
    desc: 'A practical guide to DevOps methodologies and best practices for development and operations teams.',
    link: 'https://media.licdn.com/dms/document/media/D4D1FAQGL2gxYCcCnRg/feedshare-document-pdf-analyzed/0/1724731564257?e=1726704000&v=beta&t=6crpmgV_pI9AeDEuQVZLU-HGIuJ3hKYQhnpiKpN4-pI'
  },
  {
    id: 24,
    image: require('/src/assets/images/blog/security.png'),
    title: 'Introduction to DevOps on AWS',
    desc: 'An introductory guide to implementing DevOps practices on the AWS platform.',
    link: 'https://media.licdn.com/dms/document/media/D4D1FAQFrYUVQQCSX0Q/feedshare-document-pdf-analyzed/0/1724834673552?e=1726704000&v=beta&t=wxJuvgemSrkIeShV6a1s9jvZwFR-YJkifLRHF8eYt20'
  },
  {
    id: 25,
    image: require('/src/assets/images/blog/persons.png'),
    title: 'Unlocking the Power of Linux Production Shell Scripts',
    desc: 'Master advanced shell scripting for Linux production environments with this detailed guide.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQGfh2Rg06vCqg/feedshare-document-pdf-analyzed/0/1724213199424?e=1726704000&v=beta&t=iwCtcMNg02i4qhYbcreBV2guPT3DYAYL4PQSrURCTWg'
  },
  {
    id: 26,
    image: require('/src/assets/images/blog/git.png'),
    title: 'GitOps with Argo CD',
    desc: 'Learn how to implement GitOps using Argo CD to manage your infrastructure and deployments effectively.',
    link: 'https://media.licdn.com/dms/document/media/D4D1FAQG9TxxOHMhshg/feedshare-document-pdf-analyzed/0/1724049594706?e=1726704000&v=beta&t=XPBFUZ-iHIgMGhCjHN_GSHCxgtdoNUF33EoDg1DZaU4'
  },
  {
    id: 27,
    image: require('/src/assets/images/blog/kubernetes.png'),
    title: 'Ansible for DevOps',
    desc: 'Comprehensive guide to using Ansible for automating infrastructure and configuration management in DevOps.',
    link: 'https://media.licdn.com/dms/document/media/D4D1FAQF9S1i69JmBYg/feedshare-document-pdf-analyzed/0/1724002857527?e=1726704000&v=beta&t=SbZBAkvwKEpetrtwi7oS_5leFtdjXkkxT4Fmy56hlys'
  },
  {
    id: 28,
    image: require('/src/assets/images/blog/cloud.png'),
    title: 'Complete Linux Command Recap',
    desc: 'A detailed recap of essential Linux commands for both beginners and seasoned system administrators.',
    link: 'https://media.licdn.com/dms/document/media/D4D1FAQFy5CgdUMHKmg/feedshare-document-pdf-analyzed/0/1724309878685?e=1726704000&v=beta&t=tkoHj_XCN1PxyLen-Lp_4XSp7WMnapDG3d1CBAU7tCM'
  },
  {
    id: 29,
    image: require('/src/assets/images/blog/security.png'),
    title: 'How to Set Up CI/CD Pipeline with Jenkins, Git, AWS EC2, and CodeDeploy',
    desc: 'Step-by-step guide to setting up a CI/CD pipeline using Jenkins, Git, AWS EC2, and CodeDeploy.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQEXS7YFgrpKkw/feedshare-document-pdf-analyzed/0/1724259791767?e=1726704000&v=beta&t=iyrQthLYcHZDEnA27iQmkv8dUbCM5bQLsRprS8GgaYM'
  },
  {
    id: 30,
    image: require('/src/assets/images/blog/persons.png'),
    title: 'Nmap Host Discovery Guide',
    desc: 'An essential resource for using Nmap to discover and manage network hosts efficiently.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQHxHX8D4eYy6A/feedshare-document-pdf-analyzed/0/1725202271480?e=1726704000&v=beta&t=TVxz8vRwBtpoq8c0DHx98JrooGrcT6y9Vd7202jQVF4'
  },
  {
    id: 31,
    image: require('/src/assets/images/blog/api.png'),
    title: 'AWS ECS, ECR, and Docker Project Notes',
    desc: 'Detailed notes on how to use AWS ECS, ECR, and Docker for managing containerized applications.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQGlDH6AQsJf2w/feedshare-document-pdf-analyzed/0/1723238717798?e=1726704000&v=beta&t=BzDzRCBd_XnTgb15KjhcJXPWC-JLwPtY4uWKClA4IqA'
  },
  {
    id: 32,
    image: require('/src/assets/images/blog/git.png'),
    title: 'PowerShell for Automation',
    desc: 'Learn how to use PowerShell effectively for automating various tasks and managing infrastructure.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQGdDSihtp236A/feedshare-document-pdf-analyzed/0/1724400795860?e=1726704000&v=beta&t=lbFmvrsK5D5UuH4OGIkgE8JQUygN5gUNTfgYpBO46Pg'
  },
  {
    id: 33,
    image: require('/src/assets/images/blog/cloud.png'),
    title: 'Azure DevOps: A Comprehensive Guide',
    desc: 'A detailed guide for mastering Azure DevOps and optimizing your development pipeline.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQHzmd7J0qd9-A/feedshare-document-pdf-analyzed/0/1725073332495?e=1726704000&v=beta&t=HMyM_XDBsAwKSp2F1bLcZqak3RIB88lbM2iiwLSsQso'
  },
  {
    id: 34,
    image: require('/src/assets/images/blog/kubernetes.png'),
    title: 'Terraform: Boost Your Infrastructure as Code Skills',
    desc: 'A practical resource for learning how to use Terraform for automating infrastructure management.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQFPTZp0v4vnmQ/feedshare-document-pdf-analyzed/0/1721629474347?e=1726704000&v=beta&t=t3PEQayuHYuVurTCUQQnFren_gLgaIFiuSSvUzGAIGY'
  },
  {
    id: 35,
    image: require('/src/assets/images/blog/security.png'),
    title: 'Linux Essential: Beginner’s Guide',
    desc: 'A must-have document for beginners starting their Linux journey, covering basic to intermediate concepts.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQEzESavfekGKg/feedshare-document-pdf-analyzed/0/1723293431709?e=1726704000&v=beta&t=dvAcYxxFMUI9xCPdb46tmQ5ipaG58Ftav1ziY0m9rbA'
  },
  {
    id: 36,
    image: require('/src/assets/images/blog/dashboard.png'),
    title: 'Reverse Shell Cheat Sheet',
    desc: 'An invaluable guide for cybersecurity professionals on establishing reverse shells across various platforms.',
    link: 'https://media.licdn.com/dms/document/media/D4E1FAQHy_kEbWjJetw/feedshare-document-pdf-analyzed/0/1692343229984?e=1726704000&v=beta&t=TxFtOFhHMJFSV0nvP3w-cdx0sPwmz4AIXhI5sCMcg24'
  },
  {
    id: 37,
    image: require('/src/assets/images/blog/git.png'),
    title: '100 Essential Linux Commands',
    desc: 'A goldmine for beginners and experienced Linux users, this guide covers 100 key Linux commands.',
    link: 'https://media.licdn.com/dms/document/media/D4E1FAQF-xK1ck6yRRQ/feedshare-document-pdf-analyzed/0/1696577384305?e=1726704000&v=beta&t=VN-Ntv0AdSQStwPpHUyOPml6u_tcouGx5iI771LQaC4'
  },
  {
    id: 38,
    image: require('/src/assets/images/blog/kubernetes.png'),
    title: 'Docker and Kubernetes Notes',
    desc: 'An in-depth document on Docker and Kubernetes, covering setup, deployment, and best practices.',
    link: 'https://media.licdn.com/dms/document/media/D4D1FAQFDM1NLpQ_smw/feedshare-document-pdf-analyzed/0/1718278752900?e=1726704000&v=beta&t=tij8w_e3qUuAWOzY-0dsoNWBOueYWrP5jrNWsdfJJQ0'
  },
  {
    id: 39,
    image: require('/src/assets/images/blog/api.png'),
    title: 'Terraform Cheat Sheet',
    desc: 'A comprehensive cheat sheet for managing infrastructure as code using Terraform.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQH6Qp3bJ6aqlA/feedshare-document-pdf-analyzed/0/1722219992983?e=1726704000&v=beta&t=LewwKmdJGbTSh95cSOMpWJsaN7GWHs1zgKTAP_8cLsw'
  },
  {
    id: 40,
    image: require('/src/assets/images/blog/persons.png'),
    title: 'SQL Notes',
    desc: 'Detailed notes on SQL for developers and database administrators, covering key queries and commands.',
    link: 'https://media.licdn.com/dms/document/media/D4E1FAQEQFjbZUPNLrA/feedshare-document-pdf-analyzed/0/1681123162613?e=1726704000&v=beta&t=-N9HD4PGq4xDD_wOIROMXE3UuQ2hY7LIpGjoH4pHyCw'
  },
  {
    id: 41,
    image: require('/src/assets/images/blog/dashboard.png'),
    title: 'Linux Cheat Sheet: Quick Guide for Beginners',
    desc: 'A concise Linux cheat sheet for those just starting their journey in mastering Linux commands.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQFlhEz6BA6KkQ/feedshare-document-pdf-analyzed/0/1721313905149?e=1726704000&v=beta&t=w-Y3b2rHHcIsLi-TMYAXPrHlLb3lR4ApI-U2p3r8xPs'
  },
  {
    id: 42,
    image: require('/src/assets/images/blog/git.png'),
    title: 'GitOps - Argo CD Overview',
    desc: 'An informative guide on GitOps and Argo CD for managing infrastructure and deployments through Git.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQG5biboDdX_XQ/feedshare-document-pdf-analyzed/0/1722220077269?e=1726704000&v=beta&t=Aln6G1GtnR3J05dbwUJC6NDgyq-NPeZd553ibr2r8pQ'
  },
  {
    id: 43,
    image: require('/src/assets/images/blog/security.png'),
    title: 'Linux Networking Commands Cheat Sheet',
    desc: 'A must-have resource for anyone working with Linux networking, featuring 50 critical commands.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQH5xuTbk9yrlw/feedshare-document-pdf-analyzed/0/1722412675465?e=1726704000&v=beta&t=nepIEs9alFGPJHmomOWeCo7QQAqWLjjSt0xsZUFzfjk'
  },
  {
    id: 44,
    image: require('/src/assets/images/blog/cloud.png'),
    title: 'Git Cheat Sheet: Essential Commands',
    desc: 'A helpful Git cheat sheet covering essential commands to streamline your version control workflows.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQHQ_4t_Twf6Gw/feedshare-document-pdf-analyzed/0/1724500131130?e=1726704000&v=beta&t=vyethJAB9adqPEvQsJ4037wXH1jkVFqpMVpe6u8rtYU'
  },
  {
    id: 45,
    image: require('/src/assets/images/blog/persons.png'),
    title: 'Linux Command Recap: All You Need to Know',
    desc: 'A complete recap of Linux commands to enhance your skills and efficiency in managing Linux systems.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQEXeB6MyV3-mw/feedshare-document-pdf-analyzed/0/1722425627782?e=1726704000&v=beta&t=TJ69hIH31rrcZItgbPwmgMOi3Vc1rKmF2PSu5doawSQ'
  },
  {
    id: 46,
    image: require('/src/assets/images/blog/api.png'),
    title: 'Unlocking the Power of Linux Production Shell Scripts',
    desc: 'A guide to mastering advanced shell scripting techniques for production-level Linux systems.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQGfh2Rg06vCqg/feedshare-document-pdf-analyzed/0/1724213199424?e=1726704000&v=beta&t=iwCtcMNg02i4qhYbcreBV2guPT3DYAYL4PQSrURCTWg'
  },
  {
    id: 47,
    image: require('/src/assets/images/blog/kubernetes.png'),
    title: 'DevSecOps: Early and Automated Security Remediation',
    desc: 'Learn how to integrate security at an early stage of the software development lifecycle using DevSecOps practices.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQFcjvH6wd2TEw/feedshare-document-pdf-analyzed/0/1724148956278?e=1726704000&v=beta&t=IE50N0RpbM1ZHWr5QgLEcVrqNcyGlbPhf2mcvKe2UUs'
  },
  {
    id: 48,
    image: require('/src/assets/images/blog/dashboard.png'),
    title: 'Ansible for Automation: Complete Guide',
    desc: 'A complete resource for using Ansible in automating configuration management and infrastructure provisioning.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQE4_4AauL-_5w/feedshare-document-pdf-analyzed/0/1724081117665?e=1726704000&v=beta&t=Eteyf1l2CBxLlrKzQtke5oWEHkJ2ETPA79L_nS9n8VM'
  },
  {
    id: 49,
    image: require('/src/assets/images/blog/git.png'),
    title: 'Kubernetes Cheatsheet: Essential Commands',
    desc: 'A quick reference guide for mastering key Kubernetes commands and managing clusters efficiently.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQFrbY5bVWaUfg/feedshare-document-pdf-analyzed/0/1724163188907?e=1726704000&v=beta&t=KB8Fz_B7lxLujuzgsL2uCGmbZshIOd4a9yEGZ-f0OSc'
  },
  {
    id: 50,
    image: require('/src/assets/images/blog/security.png'),
    title: 'The 2024 DevOps Toolkit',
    desc: 'An essential toolkit for DevOps engineers, covering tools and practices for modern DevOps pipelines.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQGdwjMCyK9iOA/feedshare-document-pdf-analyzed/0/1723889121056?e=1726704000&v=beta&t=FHohWRHN3F3H7PCoJJ8qHepxdbhzaK2BzdG3II-FJNs'
  },
  {
    id: 51,
    image: require('/src/assets/images/blog/dashboard.png'),
    title: 'How to Set Up CI/CD Pipeline with Jenkins and AWS',
    desc: 'A step-by-step guide to setting up a CI/CD pipeline using Jenkins, AWS EC2, and AWS CodeDeploy.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQEXS7YFgrpKkw/feedshare-document-pdf-analyzed/0/1724259791767?e=1726704000&v=beta&t=iyrQthLYcHZDEnA27iQmkv8dUbCM5bQLsRprS8GgaYM'
  },
  {
    id: 52,
    image: require('/src/assets/images/blog/git.png'),
    title: 'Prometheus Monitoring Guide',
    desc: 'An extensive guide to setting up and utilizing Prometheus for system monitoring and alerting.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQF2qfPb37p-uw/feedshare-document-pdf-analyzed/0/1725377582457?e=1726704000&v=beta&t=J2VvYN-EcPbV_v235RN69YAacoLT1VFBmS_Uw1NWE78'
  },
  {
    id: 53,
    image: require('/src/assets/images/blog/security.png'),
    title: 'AWS CloudFormation Cheat Sheet',
    desc: 'A practical cheat sheet for automating infrastructure management using AWS CloudFormation.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQG3CMTaGK41OQ/feedshare-document-pdf-analyzed/0/1725483259601?e=1726704000&v=beta&t=TjnDq_zPlnFVhLzTIyvjYRv2kSfDEaL4l8xSExDTVtQ'
  },
  {
    id: 54,
    image: require('/src/assets/images/blog/dashboard.png'),
    title: 'Linux Networking Commands Cheat Sheet',
    desc: 'A guide featuring 50 critical Linux networking commands, perfect for IT professionals.',
    link: 'https://media.licdn.com/dms/document/media/D4E1FAQGvzP4AmKYzyA/feedshare-document-pdf-analyzed/0/1725537649438?e=1726704000&v=beta&t=xh5HI6KkEOGT0eOPF4RUrrjehxGyjSPBNCIfKAd8M54'
  },
  {
    id: 55,
    image: require('/src/assets/images/blog/kubernetes.png'),
    title: 'Introduction to DevOps on AWS',
    desc: 'Learn how to implement DevOps practices on the AWS platform, covering CI/CD, monitoring, and infrastructure as code.',
    link: 'https://media.licdn.com/dms/document/media/D4D1FAQFrYUVQQCSX0Q/feedshare-document-pdf-analyzed/0/1724834673552?e=1726704000&v=beta&t=wxJuvgemSrkIeShV6a1s9jvZwFR-YJkifLRHF8eYt20'
  },
  {
    id: 56,
    image: require('/src/assets/images/blog/git.png'),
    title: 'Git Cheat Sheet: Essential Commands for Git Mastery',
    desc: 'A concise cheat sheet for mastering essential Git commands and improving productivity in version control.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQHmsKQZjGvgHQ/feedshare-document-pdf-analyzed/0/1725600373894?e=1726704000&v=beta&t=GCQD0aGztDu2FuuhkXKWl5-e-7L1N-fm4iDGPt4_HoU'
  },
  {
    id: 57,
    image: require('/src/assets/images/blog/api.png'),
    title: 'Reverse Shell Cheat Sheet',
    desc: 'A guide to establishing reverse shells across multiple platforms and languages, tailored for ethical hacking and cybersecurity professionals.',
    link: 'https://media.licdn.com/dms/document/media/D4E1FAQHy_kEbWjJetw/feedshare-document-pdf-analyzed/0/1692343229984?e=1726704000&v=beta&t=TxFtOFhHMJFSV0nvP3w-cdx0sPwmz4AIXhI5sCMcg24'
  },
  {
    id: 58,
    image: require('/src/assets/images/blog/kubernetes.png'),
    title: 'Master Linux Networking with 50 Key Commands',
    desc: 'This guide provides an overview of 50 critical Linux networking commands, enhancing your network management skills.',
    link: 'https://media.licdn.com/dms/document/media/D4E1FAQF-xK1ck6yRRQ/feedshare-document-pdf-analyzed/0/1696577384305?e=1726704000&v=beta&t=VN-Ntv0AdSQStwPpHUyOPml6u_tcouGx5iI771LQaC4'
  },
  {
    id: 59,
    image: require('/src/assets/images/blog/persons.png'),
    title: 'AWS ECS, ECR, and Docker Project Guide',
    desc: 'A detailed guide on managing containerized applications using AWS ECS, ECR, and Docker.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQGlDH6AQsJf2w/feedshare-document-pdf-analyzed/0/1723238717798?e=1726704000&v=beta&t=BzDzRCBd_XnTgb15KjhcJXPWC-JLwPtY4uWKClA4IqA'
  },
  {
    id: 60,
    image: require('/src/assets/images/blog/dashboard.png'),
    title: 'GitOps with Argo CD: Automating Infrastructure Management',
    desc: 'Learn how to implement GitOps using Argo CD to automate infrastructure and application deployments.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQH6Qp3bJ6aqlA/feedshare-document-pdf-analyzed/0/1722219992983?e=1726704000&v=beta&t=LewwKmdJGbTSh95cSOMpWJsaN7GWHs1zgKTAP_8cLsw'
  },
  {
    id: 61,
    image: require('/src/assets/images/blog/security.png'),
    title: 'Ansible for DevOps: Automating Infrastructure',
    desc: 'A practical guide to using Ansible for automating infrastructure and configuration management in DevOps.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQE4_4AauL-_5w/feedshare-document-pdf-analyzed/0/1724081117665?e=1726704000&v=beta&t=Eteyf1l2CBxLlrKzQtke5oWEHkJ2ETPA79L_nS9n8VM'
  },
  {
    id: 62,
    image: require('/src/assets/images/blog/api.png'),
    title: 'Unlocking the Power of Linux Shell Scripts',
    desc: 'Master advanced Linux shell scripting techniques to automate tasks and streamline production environments.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQGfh2Rg06vCqg/feedshare-document-pdf-analyzed/0/1724213199424?e=1726704000&v=beta&t=iwCtcMNg02i4qhYbcreBV2guPT3DYAYL4PQSrURCTWg'
  },
  {
    id: 63,
    image: require('/src/assets/images/blog/kubernetes.png'),
    title: 'Top Linux Commands Cheatsheet for Everyone',
    desc: 'An essential cheat sheet for Linux commands, providing a quick reference for beginners and experts alike.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQH1I1DmJ2ZtxQ/feedshare-document-pdf-analyzed/0/1723975704911?e=1726704000&v=beta&t=7L0v8dfwswhi5XIBOhfUIEu2r28-Ao2alC3LPJfsxu8'
  },
  {
    id: 64,
    image: require('/src/assets/images/blog/dashboard.png'),
    title: 'Azure DevOps Guide: Optimizing CI/CD Pipelines',
    desc: 'A detailed guide on optimizing your CI/CD pipelines using Azure DevOps tools and services.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQHzmd7J0qd9-A/feedshare-document-pdf-analyzed/0/1725073332495?e=1726704000&v=beta&t=HMyM_XDBsAwKSp2F1bLcZqak3RIB88lbM2iiwLSsQso'
  },
  {
    id: 65,
    image: require('/src/assets/images/blog/git.png'),
    title: 'Introduction to Docker and Kubernetes',
    desc: 'An introductory guide to Docker and Kubernetes, covering containerization and orchestration best practices.',
    link: 'https://media.licdn.com/dms/document/media/D4D1FAQFDM1NLpQ_smw/feedshare-document-pdf-analyzed/0/1718278752900?e=1726704000&v=beta&t=tij8w_e3qUuAWOzY-0dsoNWBOueYWrP5jrNWsdfJJQ0'
  },
  {
    id: 66,
    image: require('/src/assets/images/blog/api.png'),
    title: 'Practical DevOps: A Comprehensive Guide',
    desc: 'An essential guide for DevOps practitioners, covering tools, methodologies, and real-world examples.',
    link: 'https://media.licdn.com/dms/document/media/D4D1FAQGL2gxYCcCnRg/feedshare-document-pdf-analyzed/0/1724731564257?e=1726704000&v=beta&t=6crpmgV_pI9AeDEuQVZLU-HGIuJ3hKYQhnpiKpN4-pI'
  },
  {
    id: 67,
    image: require('/src/assets/images/blog/cloud.png'),
    title: 'Nmap Host Discovery Cheat Sheet',
    desc: 'A valuable cheat sheet for understanding and utilizing Nmap for host discovery and network scanning.',
    link: 'https://media.licdn.com/dms/document/media/D561FAQHxHX8D4eYy6A/feedshare-document-pdf-analyzed/0/1725202271480?e=1726704000&v=beta&t=TVxz8vRwBtpoq8c0DHx98JrooGrcT6y9Vd7202jQVF4'
  }
]

        }
    },
    computed: {
        filteredBlogs() {
            // Convert search query to lowercase
            const query = this.searchQuery.toLowerCase();

            // Filter the blogs based on the search query
            const exactMatches = this.datas.filter(item => item.title.toLowerCase().includes(query));

            // If no exact match, find the most relevant results (fuzzy logic)
            if (exactMatches.length === 0 && query !== '') {
                return this.datas.filter(item => {
                    const titleWords = item.title.toLowerCase().split(' ');
                    return titleWords.some(word => word.startsWith(query));
                });
            }
            return exactMatches;
        }
    },
    components: {
        navbar,        
        Footer,
        switcher
    },
    methods: {
        viewBlogDetail(id) {
            // Logic for viewing the blog detail
            console.log('Viewing blog detail for blog with id:', id);
        }
    }
}
</script>



<style lang="scss" scoped></style>