 <template>
  <div>
    <navbar :navLight="'nav-light'" />
    <!-- Start Hero -->
    <section
      class="relative table w-full py-36 lg:py-44 bg-no-repeat bg-center bg-cover"
      :style="{ 'background-image': `url(${data?.image ? data.image : image})` }"
    >
      <div class="absolute inset-0 bg-black opacity-75"></div>
      <div class="container relative">
        <div class="grid grid-cols-1 pb-8 text-center mt-10">
          <h3 class="mt-3 text-3xl leading-normal font-medium text-white">
            {{ data?.title ? data?.title : 'Become a Professional Graphic Designer' }}
          </h3>
        </div>
      </div>
    </section>
    <div class="relative">
      <div
        class="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900"
      >
        <svg
          class="w-full h-auto scale-[2.0] origin-top"
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- End Hero -->
  
    <!-- Start Section-->
    <section class="relative md:py-24 py-16 overflow-hidden">
      <div class="container relative">
        <div class="grid grid-cols-1">
          <h5 class="text-2xl font-semibold mb-5">Overview</h5>
          <p class="text-slate-400 mb-3">{{ data?.sec1 }}</p>
          <p class="text-slate-400 mb-3">{{ data?.sec2 }}</p>
          <p class="text-slate-400 mb-3">{{ data?.sec3 }}</p>
          <p class="text-slate-400 mb-3">{{ data?.sec4 }}</p>
        </div>
  
        <div class="grid md:grid-cols-2 grid-cols-1 mt-6 gap-[30px]">
          <img
            :src="data?.image ? data.image : blogimage"
            class="rounded-md shadow"
            alt=""
          />
  
          <div class="relative">
            <img
              src="../../assets/images/course/c8.jpg"
              class="rounded-md shadow"
              alt=""
            />
            <div
              class="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center"
            >
              <a
                @click="toggle"
                data-type="youtube"
                data-id="S_CGed6E610"
                class="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white cursor-pointer"
              >
                <i class="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
              </a>
            </div>
          </div>
        </div>
  
        
  
        
      </div>
  
      <div class="container relative md:mt-24 mt-16">
        
      </div>
    </section>
    <!-- End Section-->
  
    <!-- iframe start  -->
    <div
      :class="isActive ? 'fixed' : 'hidden'"
      class="bg-black/[0.9] top-0 left-0 bottom-0 w-[100%] h-[100%] z-999"
    >
      <div class="h-[100%] flex items-center justify-center">
        <iframe
          src="https://www.youtube.com/embed/S_CGed6E610?feature=oembed"
          width="700"
          height="500"
          frameborder="0"
        ></iframe>
      </div>
      <button class="text-slate-400 absolute top-[20px] right-[20px]">
        <svg
          stroke="currentColor"
          fill="none"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="size-5"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          @click="toggle"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
    <!-- iframe end  -->
    <Footer/>
    <switcher />
  </div>
</template>

<script>
import navbar from '@/components/navbar/navbar.vue';
import Footer from '@/components/footer/footer.vue';
import switcher from '@/components/switcher.vue';
import blogimage from '../../assets/images/course/c3.jpg';
import image from '../../assets/images/course/cta.jpg';

export default {
  data() {
    return {
      isActive: false,
      data: '',
      id: '',
      blogimage,
      image,
      datas2: [
  {
    id: 1,
    image: require('../../assets/images/course/c1.jpg'),
    image2: require('../../assets/images/client/01.jpg'),
    name: 'Calvin Carlo',
    position: 'Instructor',
    course: 'DevOps Training',
    title: 'Comprehensive DevOps Training',
    sec1: 'DevOps is a cultural and professional movement that focuses on integrating software development (Dev) and IT operations (Ops). This training covers the core principles of DevOps, teaching participants how to create CI/CD pipelines, use automation tools effectively, and adopt a DevOps mindset.',
    sec2: 'Key topics include Continuous Integration/Continuous Deployment (CI/CD), infrastructure as code (IaC), and the use of popular tools such as Jenkins, Docker, and Kubernetes. The course also emphasizes collaboration between development and operations teams to ensure smooth, continuous software delivery.',
    sec3: 'Participants will engage in hands-on labs, applying DevOps practices in real-world scenarios. By the end of the course, they will be equipped to set up, manage, and scale DevOps processes within their organizations.',
    sec4: 'The training concludes with a capstone project where participants implement a fully functional CI/CD pipeline, demonstrating their understanding of the DevOps lifecycle.',
    desc: 'Gain a deep understanding of DevOps and become a key player in your organization’s digital transformation journey.',
    lectures: '25 Lectures',
    time: '1h 30m',
    view: '3012',
    price: '$11'
  },
  {
    id: 2,
    image: require('../../assets/images/course/c2.jpg'),
    image2: require('../../assets/images/client/02.jpg'),
    name: 'Christa Smith',
    position: 'Instructor',
    course: 'Agile Training',
    title: 'Mastering Agile Methodologies',
    sec1: 'Agile is a project management methodology designed to promote flexibility, efficiency, and rapid response to change. This training focuses on the core principles of Agile and explores frameworks like Scrum and Kanban, which are widely used across various industries.',
    sec2: 'Participants will learn how to adopt an agile approach to project management, enabling them to respond more quickly to customer needs and reduce project risks. The course includes practical exercises that simulate real-world scenarios, such as Scrum sprints, daily stand-ups, and retrospectives.',
    sec3: 'The training is highly interactive, with participants working in teams to apply Agile practices. By the end of the course, they will be able to implement Agile methodologies in their own projects, leading to improved team collaboration and project outcomes.',
    sec4: 'In the final module, participants will develop an Agile project plan for a hypothetical project, applying all the concepts learned during the course.',
    desc: 'Transform your project management skills with Agile methodologies and lead your team to success.',
    lectures: '20 Lectures',
    time: '1h 15m',
    view: '2879',
    price: '$9'
  },
  {
    id: 3,
    image: require('../../assets/images/course/c3.jpg'),
    image2: require('../../assets/images/client/03.jpg'),
    name: 'Jani Jangad',
    position: 'Instructor',
    course: 'SDLC Training',
    title: 'In-Depth Software Development Life Cycle Training',
    sec1: 'The Software Development Life Cycle (SDLC) encompasses all phases of software development, from ideation through to deployment and maintenance. This course offers a comprehensive overview of SDLC, detailing each phase to help participants understand the entire software creation process.',
    sec2: 'Key areas covered include requirements analysis, system design, implementation, testing, deployment, and maintenance. Participants will learn best practices for each phase and explore the tools commonly used in SDLC to ensure high-quality software development.',
    sec3: 'The course includes case studies and hands-on exercises that give participants the opportunity to apply what they’ve learned in realistic scenarios. By the end of the training, they will have a solid foundation in managing the SDLC for complex software projects.',
    sec4: 'The training concludes with a group project where participants design and document the SDLC process for a hypothetical software application, integrating best practices learned during the course.',
    desc: 'Master the full software development lifecycle and deliver high-quality software projects.',
    lectures: '30 Lectures',
    time: '2h 00m',
    view: '4123',
    price: '$13'
  },
  {
    id: 4,
    image: require('../../assets/images/course/c4.jpg'),
    image2: require('../../assets/images/client/04.jpg'),
    name: 'John Cartwright',
    position: 'Instructor',
    course: 'Git Training',
    title: 'Proficient Git Usage for Version Control',
    sec1: 'Git is an essential tool in modern software development, providing a distributed version control system that enables efficient source code management. This training covers the fundamental concepts of Git and how to use it effectively in team environments.',
    sec2: 'Participants will learn key Git commands and operations, including branching, merging, and rebasing. The course also explores how to use Git in conjunction with popular platforms like GitHub and GitLab, enabling seamless collaboration on projects.',
    sec3: 'Throughout the course, participants will engage in hands-on exercises that simulate real-world development scenarios. By the end of the training, they will have the skills needed to manage codebases effectively and collaborate efficiently with other developers.',
    sec4: 'The training wraps up with a capstone project where participants set up a Git repository, implement a branching strategy, and manage a team project, demonstrating their proficiency in using Git for version control.',
    desc: 'Become proficient in Git and streamline your development workflow with effective version control strategies.',
    lectures: '18 Lectures',
    time: '1h 20m',
    view: '2798',
    price: '$10'
  },
  {
    id: 5,
    image: require('../../assets/images/course/c5.jpg'),
    image2: require('../../assets/images/client/05.jpg'),
    name: 'Sally Short',
    position: 'Instructor',
    course: 'Docker Training',
    title: 'Comprehensive Docker Training',
    sec1: 'Docker revolutionizes the way software is developed, tested, and deployed by providing a consistent environment through containerization. This course covers the essential concepts and practices of Docker, teaching participants how to create, manage, and scale Docker containers.',
    sec2: 'Key topics include Docker architecture, writing Dockerfiles, using Docker Compose for multi-container applications, and orchestrating containers with Docker Swarm. The course also addresses container security best practices.',
    sec3: 'Participants will work on practical exercises that involve building and deploying containerized applications. By the end of the training, they will have the expertise needed to integrate Docker into their development workflows and manage containerized environments effectively.',
    sec4: 'The course concludes with a final project where participants design and implement a Docker-based solution for a real-world application, showcasing their ability to utilize Docker in a professional setting.',
    desc: 'Master Docker and learn how to manage containerized applications with confidence.',
    lectures: '22 Lectures',
    time: '1h 45m',
    view: '3546',
    price: '$12'
  },
  {
    id: 6,
    image: require('../../assets/images/course/c6.jpg'),
    image2: require('../../assets/images/client/06.jpg'),
    name: 'William Benson',
    position: 'Instructor',
    course: 'CI/CD Training',
    title: 'Advanced CI/CD Implementation',
    sec1: 'Continuous Integration and Continuous Delivery/Deployment (CI/CD) are key practices in modern software development that ensure code changes are integrated and delivered efficiently. This course provides an in-depth look at CI/CD principles and tools.',
    sec2: 'Participants will learn how to automate the software development lifecycle using tools like Jenkins, GitLab CI, and CircleCI. The course covers pipeline creation, automated testing, and managing continuous delivery processes to ensure high-quality code is released to production.',
    sec3: 'Hands-on labs give participants practical experience in setting up CI/CD pipelines and managing deployments. By the end of the course, they will be proficient in implementing CI/CD processes that accelerate development cycles and enhance product quality.',
    sec4: 'The training concludes with a capstone project where participants build and deploy a complete CI/CD pipeline for a sample application, demonstrating their ability to automate the software delivery process.',
    desc: 'Learn how to implement CI/CD pipelines and automate your software development processes.',
    lectures: '24 Lectures',
    time: '2h 10m',
    view: '3882',
    price: '$14'
  },
  {
    id: 7,
    image: require('../../assets/images/course/c7.jpg'),
    image2: require('../../assets/images/client/01.jpg'),
    name: 'William Benson',
    position: 'Instructor',
    course: 'GitOps Training',
    title: 'Modern Infrastructure Management with GitOps',
    sec1: 'GitOps is a cutting-edge approach that centralizes infrastructure management and deployment processes using Git as the source of truth. This course teaches the core concepts of GitOps and how to apply them in real-world scenarios.',
    sec2: 'Participants will learn how to define infrastructure as code using Git, manage changes, and automate deployments through integration with Kubernetes. The training includes hands-on experience with tools like ArgoCD and Flux, which are essential for implementing GitOps practices.',
    sec3: 'The course provides practical labs that help participants develop and deploy infrastructure using GitOps methodologies. By the end of the training, participants will be able to implement and manage GitOps workflows effectively, ensuring infrastructure is consistently and securely managed through code.',
    sec4: 'The course wraps up with a capstone project where participants create and manage a Kubernetes environment using GitOps practices, demonstrating their ability to automate infrastructure management using Git.',
    desc: 'Master GitOps and take control of your infrastructure management with cutting-edge practices.',
    lectures: '20 Lectures',
    time: '1h 50m',
    view: '3250',
    price: '$13'
  },
  {
    id: 8,
    image: require('../../assets/images/course/c8.jpg'),
    image2: require('../../assets/images/client/02.jpg'),
    name: 'William Benson',
    position: 'Instructor',
    course: 'DevSecOps Training',
    title: 'Integrating Security in DevOps Processes',
    sec1: 'DevSecOps is an approach that integrates security practices into every stage of the software development and operations processes. This course provides an in-depth understanding of how to implement DevSecOps, ensuring that security is built into the DevOps pipeline from the start.',
    sec2: 'Participants will learn about security automation tools, secure coding practices, and how to monitor and respond to security threats in real-time. The course also covers key DevSecOps tools like SonarQube, OWASP ZAP, and how to integrate these tools into CI/CD pipelines.',
    sec3: 'Through hands-on labs, participants will practice setting up automated security testing, vulnerability scanning, and continuous monitoring in a DevOps environment. By the end of the course, they will be equipped to create secure, resilient software that meets industry standards.',
    sec4: 'The training concludes with a practical project where participants set up a fully automated DevSecOps pipeline, integrating security at every stage of the development lifecycle.',
    desc: 'Secure your DevOps processes with DevSecOps practices and ensure compliance with industry standards.',
    lectures: '26 Lectures',
    time: '2h 15m',
    view: '3720',
    price: '$15'
  },
  {
    id: 9,
    image: require('../../assets/images/course/c9.jpg'),
    image2: require('../../assets/images/client/03.jpg'),
    name: 'William Benson',
    position: 'Instructor',
    course: 'Azure Cloud Training',
    title: 'Comprehensive Azure Cloud Mastery',
    sec1: 'Azure is Microsoft’s cloud platform that offers a wide range of services for computing, storage, and networking, among others. This course covers the fundamental concepts of Azure and provides hands-on training on how to effectively utilize Azure services.',
    sec2: 'Participants will learn how to set up virtual machines, manage Azure resources, and deploy cloud-based applications. The course also covers Azure DevOps, Azure Resource Manager, and security practices to ensure that Azure environments are managed efficiently and securely.',
    sec3: 'Hands-on labs guide participants through the process of setting up and managing Azure services, integrating them into their existing workflows. By the end of the course, they will have the skills needed to manage Azure-based infrastructure and applications effectively.',
    sec4: 'The course concludes with a capstone project where participants design and deploy a complete cloud solution using Azure, demonstrating their ability to leverage Azure for modern cloud computing needs.',
    desc: 'Master Azure Cloud services and gain the skills needed to manage and deploy cloud-based applications effectively.',
    lectures: '28 Lectures',
    time: '2h 30m',
    view: '4000',
    price: '$16'
  }
]
    };
  },
  components: {
    navbar,
    Footer,
    switcher    
  },
  mounted() {
    this.id = this.$route.params.id;
    console.log('Mounted with ID:', this.id); // Debugging log
    this.fetchData();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    console.log('Route updated:', to.params.id); // Debugging log
    this.fetchData();
    next();
  },
  methods: {
    fetchData() {
      this.data = this.datas2.find((item) => item.id === parseInt(this.id));
      console.log('Fetched data:', this.data); // Debugging log
    },
    toggle() {
      this.isActive = !this.isActive;
    }
  }
};
</script>

<style lang="scss" scoped>
/* Add your component-specific styles here */
</style>