<template>

    <div v-if="powerfull" class="container relative md:mt-24 mt-16">
        <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div class="md:col-span-6 order-1 md:order-2">
                <div class="lg:ms-8">
                    <img src="../assets/images/shape-image.png" alt="">
                </div>
            </div>

            <div class="md:col-span-6 order-2 md:order-1">
                <div class="lg:me-5">
                    <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">Fast & Effective</h6>
                   
                    <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Powerful
                        Digitalization <br></h3>

                    <p class="text-slate-400 max-w-xl mb-6">My experience spans multiple industries, including finance, government, and technology, where I've implemented agile methodologies, automated processes, and secured critical systems. I am passionate about leveraging technology to solve real-world problems and continuously improve organizational efficiency.</p>

                  
                </div>
            </div>
        </div>
    </div><!--end container-->

    <div v-else class="container relative md:mt-24 mt-16">
        <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div class="md:col-span-6">
                <div class="lg:me-8">
                    <img src="../assets/images/shape-image.png" alt="">
                </div>
            </div>

            <div class="md:col-span-6">
                <div class="lg:ms-5">
                    <h6 v-if="History" class="text-indigo-600 text-sm font-bold uppercase mb-2">About</h6>
                    <h6 v-else class="text-indigo-600 text-sm font-bold uppercase mb-2">Fast & Effective</h6>
                    <h3 v-if="Effective" class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">“With a deep passion for technology.</h3>
                   

                    <p class="text-slate-400 max-w-xl mb-6">With a deep passion for technology, I have dedicated my career to mastering the intricacies of DevOps, cloud computing, and software development. My experience spans multiple industries, including finance, government, and technology, where I’ve successfully led complex projects that bridge the gap between development and operations. I thrive on solving real-world problems with innovative solutions, ensuring that businesses not only keep up with the fast-paced tech landscape but also lead it. Whether it's through enhancing security, automating processes, or optimizing cloud-based systems, my goal is to empower organizations to achieve their full potential.</p>

                    
                </div>
            </div>
        </div>
    </div><!--end container-->
</template>

<script>
export default {
    props: {
        powerfull: {
            type: Boolean,
            required: true
        },
        Effective:{
            type: Boolean,
            required: true
        },
        History:{
            type: Boolean,
            required: true
        }
    },
    data() {
        return {

        }
    },

}
</script>

<style lang="scss" scoped></style>