<template>
    <navbar :navLight="'nav-light'" />
    <!-- Start Hero -->
    <section class="relative table w-full py-32 lg:py-40 bg-[url('/src/assets/images/portfolio/bg-inner.jpg')] bg-no-repeat bg-center bg-cover">
      <div class="absolute inset-0 bg-gradient-to-b from-black/60 via-black/80 to-black"></div>
      <div class="container relative">
        <div class="grid grid-cols-1 pb-8 text-center mt-10">
          <h3 v-if="data" class="mb-3 text-3xl leading-normal font-medium text-white">{{ data.title }}</h3>
          <ul v-if="data" class="list-none">
            <li class="inline font-semibold text-white/60 me-2"><span class="text-white">Client :</span> {{ data.client }}</li>
            <li class="inline font-semibold text-white/60"><span class="text-white">Date :</span> {{ data.date }}</li>
          </ul>
        </div><!--end grid-->
      </div><!--end container-->
      <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul class="tracking-[0.5px] mb-0 inline-block">
          <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><router-link to="/">Techwind</router-link></li>
          <li class="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="uil uil-angle-right-b"></i></li>
          <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><router-link to="/portfolio">Portfolio</router-link></li>
          <li class="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="uil uil-angle-right-b"></i></li>
          <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">{{ data?.title }}</li>
        </ul>
      </div>
    </section><!--end section-->
   
    <div class="relative">
      <div class="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!-- End Hero -->
   
    <!-- Start Section-->
    <section class="relative md:py-24 py-16">
      <div class="container relative">
        <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
          <div class="lg:col-span-5 md:col-span-6">
            <div class="grid grid-cols-1 gap-[30px]">
              <img v-if="data" :src="data.images[0]" class="rounded-md" alt="">
              <img v-if="data" :src="data.images[1]" class="rounded-md" alt="">
              <img v-if="data" :src="data.images[2]" class="rounded-md" alt="">
            </div><!--end grid-->
          </div><!--end col-->
   
          <div class="lg:col-span-7 md:col-span-6">
            <div class="sticky top-20">
              <div class="grid lg:grid-cols-12 grid-cols-1 gap-[30px]">
                <div class="lg:col-span-12">
                  <div class="work-details">
                    <h4 class="text-xl font-semibold mb-3 border-b border-gray-100 dark:border-gray-700 pb-3">Project Description :</h4>
                    <p v-if="data" class="text-slate-400">{{ data.description }}</p>
                  </div>
                </div><!--end col-->
                                 
                <div class="lg:col-span-7">
                  <div class="bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 p-6 rounded-md">
                    <h5 class="text-lg font-semibold border-b border-gray-100 dark:border-gray-700 pb-3 mb-3">Project Info :</h5>
                    <dl v-if="data" class="grid grid-cols-12 mb-0">
                      <dt class="md:col-span-4 col-span-5 mt-2">Client :</dt>
                      <dd class="md:col-span-8 col-span-7 mt-2 text-slate-400">{{ data.client }}</dd>
   
                      <dt class="md:col-span-4 col-span-5 mt-2">Category :</dt>
                      <dd class="md:col-span-8 col-span-7 mt-2 text-slate-400">{{ data.category }}</dd>
   
                      <dt class="md:col-span-4 col-span-5 mt-2">Date :</dt>
                      <dd class="md:col-span-8 col-span-7 mt-2 text-slate-400">{{ data.date }}</dd>
   
                      <dt class="md:col-span-4 col-span-5 mt-2">Website :</dt>
                      <dd class="md:col-span-8 col-span-7 mt-2 text-slate-400"><a :href="data.website">{{ data.website }}</a></dd>
   
                      <dt class="md:col-span-4 col-span-5 mt-2">Location :</dt>
                      <dd class="md:col-span-8 col-span-7 mt-2 text-slate-400">{{ data.location }}</dd>
                    </dl>  
                  </div>
                </div><!--end col-->
              </div><!--end grid-->
            </div>
          </div><!--end col-->
        </div><!--end grid-->
      </div><!--end container-->
      <contact />
    </section><!--end section-->
    <!-- End Section-->
    <Footer/>
    <switcher />
  </template>
   
  <script>
  import contact from '@/components/portfolio-contact.vue';
  import navbar from '@/components/navbar/navbar.vue';
  import Footer from '@/components/footer/footer.vue';
  import switcher from '@/components/switcher.vue';
   
  export default {
    data() {
      return {
        isActive: false,
        id: '',
        data: null, // Initialize as null to hold the selected project data
        datas: [
  {
    id: 1,
    title: 'Ministry of Treasury and Finance DevOps Transformation Project',
    client: 'Ministry of Treasury and Finance, Turkey',
    date: 'Completed: June 2023',
    category: 'DevOps Transformation',
    website: 'https://www.maliye.gov.tr',
    location: 'Ankara, Turkey',
    description: `This DevOps transformation project for Turkey's Ministry of Treasury and Finance stands as a prime example of how digital transformation can be successfully implemented within the public sector. We optimized the software development processes within the Ministry by integrating modern DevOps methodologies. By establishing Continuous Integration (CI) and Continuous Delivery (CD) pipelines, we accelerated software deployments and enhanced security protocols. Automation tools were deployed to minimize manual processes, thus reducing the risk of human error. As a result, the IT processes within the Ministry are now faster, more secure, and highly efficient, supporting the Ministry's critical operations.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  },
  {
    id: 2,
    title: 'Turkcell, Türk Telekom, Vodafone Joint Data Storage System Project',
    client: 'Turkcell, Türk Telekom, Vodafone',
    date: 'Completed: December 2022',
    category: 'Data Management & Storage',
    website: 'https://www.turkcell.com.tr',
    location: 'Istanbul, Turkey',
    description: `This project, developed for Turkey's three largest telecommunications companies—Turkcell, Türk Telekom, and Vodafone—is a benchmark for large-scale data management and storage solutions. We created a centralized data management platform that securely and effectively stores data from different telecom operators. The platform ensures the highest levels of data security and privacy, fully compliant with legal regulations. This project has facilitated data sharing between operators and significantly improved operational efficiency, setting a new standard in the telecommunications industry.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  },
  {
    id: 3,
    title: 'ING Group Turkey B2B/B2C Azure Cloud and DevOps Project',
    client: 'ING Group, Turkey',
    date: 'Completed: March 2023',
    category: 'Cloud Migration & DevOps',
    website: 'https://www.ing.com.tr',
    location: 'Istanbul, Turkey',
    description: `This project for ING Group, one of the key players in Turkey's financial sector, involved migrating their B2B/B2C systems to Azure Cloud and integrating DevOps processes. It serves as a model for successfully transitioning financial services to cloud infrastructure. We migrated ING's existing systems to a cloud environment, enhancing scalability, security, and flexibility. Simultaneously, we adopted DevOps principles, which accelerated software development processes and reduced operational costs. This project marked a significant step in ING's digital transformation journey, enabling them to offer more agile and secure financial services.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  },
  {
    id: 4,
    title: 'Bilyoner Amazon Cloud Transformation Project',
    client: 'Bilyoner',
    date: 'Completed: August 2022',
    category: 'Cloud Migration',
    website: 'https://www.bilyoner.com',
    location: 'Istanbul, Turkey',
    description: `This transformation project for Bilyoner, Turkey's leading online betting platform, involved migrating to Amazon Web Services (AWS) cloud infrastructure. By moving Bilyoner's high-traffic platform to AWS, we achieved significant improvements in performance and reliability. The new system supports automatic scaling, allowing it to handle traffic spikes seamlessly. Additionally, we implemented high security standards and data protection solutions, ensuring the privacy and integrity of user data. This transformation enhanced Bilyoner's operational flexibility while optimizing costs, setting a new standard in the online gaming industry.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  },
  {
    id: 5,
    title: 'Vakıfbank DevOps and IT Operations Transformation Project',
    client: 'Vakıfbank',
    date: 'Completed: April 2023',
    category: 'DevOps & IT Operations',
    website: 'https://www.vakifbank.com.tr',
    location: 'Istanbul, Turkey',
    description: `This DevOps and IT Operations (ItOps) transformation project for Vakıfbank is a robust example of digital transformation in the banking sector. We restructured Vakıfbank's software development and operations processes using DevOps tools and automation solutions, accelerating software development cycles and ensuring error-free deployments. Additionally, we optimized ItOps processes, making Vakıfbank's IT infrastructure more flexible and scalable. As a result, Vakıfbank is now able to offer digital services more quickly and securely, enhancing its competitive edge in the financial market.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  },
  {
    id: 6,
    title: 'Paycore DevOps Transformation Project',
    client: 'Paycore',
    date: 'Completed: November 2022',
    category: 'DevOps Transformation',
    website: 'https://www.paycore.com',
    location: 'Istanbul, Turkey',
    description: `This DevOps transformation project for Paycore, a leader in payment systems, is critical for financial service providers. We redefined Paycore's software development and deployment processes using modern DevOps methodologies. By establishing Continuous Integration and Delivery pipelines, we enhanced software quality and reduced deployment times. Security automation was also a key focus, ensuring the highest levels of security for payment systems. This project has enabled Paycore to bring products to market more rapidly and securely, significantly improving their service delivery capabilities.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  },
  {
    id: 7,
    title: 'Multinet DevOps Transformation Project',
    client: 'Multinet',
    date: 'Completed: February 2023',
    category: 'DevOps Transformation',
    website: 'https://www.multinet.com.tr',
    location: 'Istanbul, Turkey',
    description: `The DevOps transformation project for Multinet, a provider of corporate payment solutions, aimed to modernize the company's digital infrastructure. We optimized Multinet's software development processes and enhanced operational efficiency using DevOps tools and automation processes. This led to significant reductions in software deployment times and improvements in quality control processes. Additionally, we ensured seamless system integration, enabling smooth business operations. This transformation has allowed Multinet to deliver digital services more quickly and reliably, boosting their market competitiveness.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  },
  {
    id: 8,
    title: 'Erdemir DevOps Transformation Project',
    client: 'Erdemir',
    date: 'Completed: January 2023',
    category: 'DevOps Transformation',
    website: 'https://www.erdemir.com.tr',
    location: 'Zonguldak, Turkey',
    description: `This DevOps transformation project for Erdemir, Turkey's largest steel producer, is a significant example of digitalization in industrial production. We restructured Erdemir's IT processes to accelerate software development cycles and increase operational efficiency. By implementing Continuous Integration and Delivery processes, we ensured the smooth operation of production systems. Furthermore, we modernized the IT infrastructure to support Erdemir's digital transformation journey. This project has made Erdemir's production processes more efficient and reduced operational costs, enhancing their competitiveness in the global market.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  },
  {
    id: 9,
    title: 'Testinium Products DevOps Transformation and Cloud Compatibility Project',
    client: 'Testinium',
    date: 'Completed: October 2022',
    category: 'DevOps & Cloud Migration',
    website: 'https://www.testinium.com',
    location: 'Istanbul, Turkey',
    description: `This project for Testinium, a leader in software test automation, involved DevOps transformation and cloud compatibility. We optimized Testinium's software development and deployment processes using DevOps methodologies and migrated their products to a cloud environment, making them more flexible and scalable. Automation solutions were implemented to minimize manual processes, improving software quality and reducing deployment times. This project has enhanced Testinium's competitiveness, allowing them to bring products to market more quickly and reliably.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  },
  {
    id: 10,
    title: 'Ofix DevOps and Azure Cloud Transformation Project',
    client: 'Ofix',
    date: 'Completed: May 2023',
    category: 'DevOps & Cloud Migration',
    website: 'https://www.ofix.com',
    location: 'Istanbul, Turkey',
    description: `This project for Ofix, a leading office supplies and stationery company, involved a comprehensive DevOps transformation and migration to Azure Cloud. We optimized Ofix's software development processes using modern DevOps tools and methodologies, enabling faster and more reliable software deployments. The transition to Azure Cloud provided Ofix with a more flexible, secure, and scalable IT infrastructure. We implemented Continuous Integration and Delivery (CI/CD) pipelines, significantly reducing deployment times and enhancing product quality. This transformation allowed Ofix to offer its digital services more efficiently, thereby improving customer satisfaction and operational efficiency.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  },
  {
    id: 11,
    title: 'Ofix KVKK Corporate Compliance Project',
    client: 'Ofix',
    date: 'Completed: November 2022',
    category: 'Compliance & Security',
    website: 'https://www.ofix.com',
    location: 'Istanbul, Turkey',
    description: `This project for Ofix was focused on ensuring full compliance with the Turkish Personal Data Protection Law (KVKK). We conducted a comprehensive analysis of Ofix's data processing activities, made the necessary adjustments, and implemented new policies to ensure compliance with KVKK. The project included the provision of training for employees to raise awareness about data protection and compliance. As a result, Ofix not only safeguarded customer data but also ensured that all operations were fully compliant with legal regulations, thus protecting the company from potential legal risks.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  },
  {
    id: 12,
    title: 'Boğaziçi University TÜBİTAK Security Compliance Project',
    client: 'Boğaziçi University',
    date: 'Completed: February 2023',
    category: 'Security Compliance',
    website: 'https://www.boun.edu.tr',
    location: 'Istanbul, Turkey',
    description: `This project for Boğaziçi University aimed to ensure compliance with the security standards set by TÜBİTAK (The Scientific and Technological Research Council of Turkey). We conducted a thorough security analysis of the university's information systems and aligned them with TÜBİTAK's stringent security requirements. The project involved the implementation of robust solutions in areas such as data protection, network security, access control, and security monitoring. Additionally, we provided security training to university staff to enhance awareness and preparedness. This project strengthened Boğaziçi University's information security framework, ensuring the safety and integrity of its research and educational processes.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  },
  {
    id: 13,
    title: 'Testinium Products BizDevOps Projects',
    client: 'Testinium',
    date: 'Completed: April 2023',
    category: 'BizDevOps',
    website: 'https://www.testinium.com',
    location: 'Istanbul, Turkey',
    description: `The BizDevOps projects for Testinium were designed to bridge the gap between business processes and DevOps methodologies. We developed solutions that enabled Testinium's business units to work more closely and efficiently with software development teams. This approach facilitated faster translation of business requirements into software features, allowing products to reach the market more quickly. By establishing a continuous feedback loop between business units and development teams, we improved software quality and enhanced customer satisfaction. These projects significantly boosted Testinium's agility and responsiveness to market demands.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  },
  {
    id: 14,
    title: 'Felixo Azure Cloud Management and Cost Optimization Project',
    client: 'Felixo',
    date: 'Completed: June 2023',
    category: 'Cloud Management & Optimization',
    website: 'https://www.felixo.com',
    location: 'Istanbul, Turkey',
    description: `This project for Felixo, a digital asset trading platform, focused on Azure Cloud management and cost optimization. We analyzed Felixo's cloud infrastructure and developed strategies to enhance cost efficiency. By implementing automatic scaling, resource optimization, and cloud cost management tools, we significantly reduced operational expenses while maintaining high performance. We also strengthened security measures to protect digital assets and user data. This project resulted in a more efficient and sustainable cloud infrastructure for Felixo, enabling them to scale their operations while keeping costs under control.`,
    images: [require('@/assets/images/portfolio/01.jpg'), require('@/assets/images/portfolio/02.jpg'), require('@/assets/images/portfolio/03.jpg')]
  }
],
      };
    },
    components: {
      navbar,
      contact,
      Footer,
      switcher,
    },
    mounted() {
      this.id = this.$route.params.id;
      console.log('Mounted with ID:', this.id); // Debugging log
      this.fetchData();
    },
    beforeRouteUpdate(to, from, next) {
      this.id = to.params.id;
      console.log('Route updated:', to.params.id); // Debugging log
      this.fetchData();
      next();
    },
    methods: {
      fetchData() {
        this.data = this.datas.find((item) => item.id === parseInt(this.id));
        console.log('Fetched data:', this.data); // Debugging log
      },
      toggle() {
        this.isActive = !this.isActive;
      }
    }
  };
  </script>
   
  <style lang="scss" scoped>
  /* Add any scoped styles here if needed */
  </style>